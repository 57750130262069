.header__menu {
  //width: 100%;
  gap: 15px;
  display: flex;
  justify-content: space-between;
}

.header__closeMenu {
  display: none;
}
@media screen and (max-width: 1200px) {
  .header__menu {
    transition: 0.5s left;
    position: fixed;
    width: 100%;
    max-width: 258px;
    height: 100%;
    left: -258px;
    top: 0;
    background: white;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
  }
  .header__menu_open {
    z-index: 10;
    left: 0;
  }

  .header__closeMenu {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    background: url("/landing/modalAuth/closeModal.svg") no-repeat;
    width: 20px;
    height: 20px;
  }
}
