.experience__relevance {
  margin-bottom: 10px;
  padding: 8px 16px;
  width: 145px;
  height: 29px;
  background: rgba(225, 122, 72, 0.2);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
