.auth__input {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #737a92;
  }
  &:hover {
    border: 1px solid #737a92;
  }

  &:focus {
    outline: none;
    border: 1px solid #282c38;
  }
}
