.application {
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 833px;
}

@media screen and(max-width: 1200px) {
  .application {
    padding-bottom: 80px;
    padding-top: 80px;
    flex-direction: column;
    height: max-content;
  }
}

.application__text {
  max-width: 380px;
  display: grid;
  grid-gap: 64px;
}

.application__button {
  max-width: 330px;
  height: 54px;
}

.application__tapes {
  width: 580px;
  height: 100%;
  display: flex;
  justify-content: space-between;

  padding: 20px 0;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .application__tapes {
    display: grid;
    grid-template-rows: max-content max-content;
    justify-content: unset;
    overflow: scroll;
    grid-gap: 40px;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.application__tape {
  height: 100%;
  display: grid;
  grid-auto-rows: 384px;
  grid-template-columns: 280px;
  //grid-gap: 44px;
}

@media screen and(max-width: 1200px) {
  .application__tape {
    height: max-content;

    grid-auto-rows: unset;
    grid-template-columns: unset;

    grid-auto-flow: column;

    grid-auto-columns: minmax(200px, 200px);
    grid-template-rows: minmax(243px, 243px);
    justify-content: center;
    grid-gap: 20px;
  }
}

.application__tape_indent {
  padding-top: 214px;
}
@media screen and(max-width: 1200px) {
  .application__tape_indent {
    padding-top: 0;
  }
}

.application__partner {
  padding: 22px 0;
}

.application__partnerContent {
  background: #eceef3;
  box-shadow: 0px 54px 54px -30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
