.passwordRecovery,
.RecoveryCodeLevel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .passwordRecovery,
  .RecoveryCodeLevel {
    padding: 0 10px;
  }
}

.passwordRecovery__content {
  width: 100%;
  height: 100%;
  max-width: 311px;
  max-height: 334px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.RecoveryCodeLevel__content {
  width: 100%;
  height: 100%;
  max-width: 320px;
  max-height: 464px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 540px) {
  .RecoveryCodeLevel__content {
    max-height: 440px;
  }
}

.passwordRecovery__data {
  height: 100%;
  max-height: 208px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.RecoveryCodeLevel__data {
  height: 100%;
  max-height: 308px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.passwordRecovery__description {
  height: 100%;
  max-height: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
