.appealForm__content {
  position: relative;
  width: 100%;
  max-width: 1180px;
  height: 100%;
  max-height: 700px;
  background: #3a87a3;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 120px 100px;
  padding-right: 0px;
  border-radius: 30px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    right: -920px;
    background: url("/landing/appealForm/appealForm__decoration.svg") no-repeat;
    width: 1530px;
    height: 1194px;
    opacity: 0.1;
  }
}

@media screen and (max-width: 1200px) {
  .appealForm__content {
    padding: 56px 24px;
    max-height: 600px;
  }
}
