.companyDescription__aboutCompany {
  max-width: 898px;
  display: grid;
  grid-template-rows: 34px max-content;
  grid-gap: 32px;

  @extend %paragraph;
  font-size: 40px;
  line-height: 120%;
  color: #282c38;
}

@media screen and (max-width: 1200px) {
  .companyDescription__aboutCompany {
    font-size: 20px;
  }
}
