.modalAuth__close {
  cursor: pointer;
  position: absolute;
  background: url("/landing/modalAuth/closeModal.svg") center center no-repeat;
  height: 24px;
  width: 24px;
  background-size: contain;
  top: 20px;
  right: 20px;
  z-index: 11;
}

@media screen and (max-width: 540px) {
  .modalAuth__close_hiddenForPhone {
    display: none;
  }
  .modalAuth__close {
    //position: static;
  }
}
