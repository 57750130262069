.control {
  height: 922px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control__content {
  position: relative;

  overflow: hidden;

  z-index: 1;
  width: 1180px;
  height: 800px;

  background: #3a87a3;
  box-shadow: 0px 64px 174px -50px rgba(0, 0, 0, 0.2);
  border-radius: 30px;

  padding-top: 110px;
  display: flex;
  justify-content: center;

  &:before {
    position: absolute;
    content: "";
    background: url("/landing/to-customers/vector.svg") center center no-repeat;
    width: 850px;
    opacity: 0.2;
    height: 1089px;
    left: -400px;
    bottom: -400px;
  }
  &:after {
    position: absolute;
    content: "";
    background: url("/landing/to-customers/vector.svg") center center no-repeat;
    width: 850px;
    opacity: 0.2;
    height: 1089px;
    right: -400px;
    top: -400px;
  }
}

.control__text {
  max-width: 682px;

  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 50px;
  text-align: center;
}

.control__title {
  font-size: 34px;
}

.control__tablet {
  position: absolute;
  z-index: 2;
  background-image: url("/landing/to-customers/tablet.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 784px;
  height: 653px;
  bottom: -78px;
}

.control__tablet_en{
  background-image: url("/landing/to-customers/tablet_en.png");
}

.control__tablet_uz{
  background-image: url("/landing/to-customers/tablet_uz.png");
}

@media screen and (max-width: 1200px) {
  .control__content {
    height: 592px;
  }
  .control__text {
    max-width: 575px;
    padding: 0 20px;
  }
  .control__tablet {
    background-position: bottom center;
    width: 320px;
    height: 266.04px;
    bottom: 0;
  }

  .control__title {
    font-size: 20px;
  }
}
