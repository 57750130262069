.experience__img {
  background-image: url("/landing/main/experience/experience.png") ;
  width: 572px;
  height: 497px;
  background-size: contain;
  background-repeat: no-repeat;
}

.experience__img_en {
  background-image: url("/landing/main/experience/experience_en.png") ;
}
@media screen and (max-width: 900px) {
  .experience__img {
   background-position: center bottom;
    width: 330px;
    height: 262px;
    background-size: contain;
  }
}

@media screen and (max-width: 540px) {
  .experience__img {
    background-position: center center;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    background-size: contain;
  }
}
