.companyDescription__mainTitle {
  @extend %paragraph;
  font-size: 40px;
  line-height: 120%;
  color: #282c38;
}

@media screen and (max-width: 1200px) {
  .companyDescription__mainTitle {
    font-size: 36px;
  }
}

@media screen and (max-width: 540px) {
  .companyDescription__mainTitle {
    font-size: 20px;
  }
}
