.appealForm__field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.appealForm__inputFiled {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .appealForm__field {
    height: 132px;
    flex-direction: column;
    align-items: center;

    > :first-child {
      margin-right: 0;
    }
  }
}
