.companyDescription__content {
  margin-top: 124px;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 186px;
}

@media screen and (max-width: 1200px) {
  .companyDescription__content {
    margin-top: 96px;
    grid-gap: 86px;
  }
}
