.example__text {
  z-index: 1;
  height: 100%;
  max-width: 335px;

  display: flex;

  flex-direction: column;

  justify-content: space-between;

  grid-area: 1/1/1/3;

  margin-left: 20px;
}

.example__text_center {
  justify-content: center;
}
