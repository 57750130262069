.functionsAndRates__description {
  max-width: 400px;
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 32px;
}

@media screen and(max-width: 1200px) {
  .functionsAndRates__description {
    z-index: 1;
    max-width: 318px;
    height: max-content;
    align-items: center;
  }
}
