.contacts {
  position: relative;
  overflow: hidden;
}

.contacts__content {
  height: 100%;
  width: 100%;
  padding: 114px 0 132px 0;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 120px;
  grid-template-columns: 100%;
}

.contacts__description {
  max-width: 777px;
  display: grid;
  grid-gap: 64px;
  grid-template-rows: max-content 54px;
}

.contacts__info {
  z-index: 1;
  display: grid;
  grid-template-rows: max-content;
  grid-gap: 78px;
}

.contacts__contacts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
  grid-gap: 80px 32px;
}

.contacts__contact {
  display: grid;
  grid-gap: 9px;
}

.contacts__contactName {
  @extend %paragraph;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 140%;
  letter-spacing: 0.1em;
  color: #282c38;
  opacity: 0.5;
}

.contacts__contactData {
  @extend %paragraph;
  font-size: 20px;
  line-height: 140%;
  color: #282c38;
}

.contacts__map {
  //background: url("/landing/Contacts/map.png") center center no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  box-shadow: 0px 104px 114px -50px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

@media screen and (max-width: 1200px) {
  .contacts__button {
    width: 100%;
    max-width: 280px;
  }

  .contacts__map {
  }
}

.circleContacts {
  position: absolute;
  width: 1907px;
  height: 1907px;

  left: 50%;
  transform: translate(-50%, 0);
  bottom: -90%;
  border-radius: 50%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(101, 101, 101, 0.5) 0%,
    rgb(108 255 188 / 19%) 100%
  );
  filter: blur(44px);
}
