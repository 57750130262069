.table__head_finance, .table__row_finance{
  grid-template-columns:
          1fr 1fr 1fr 1fr
}

@media screen and (max-width: 850px) {
  .table__head_finance,  .table__row_finance{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .table__row_task {

    .table__cell {
      &:first-child{
        display: none;
      }
      &:nth-last-of-type(4){
        border-bottom: unset;
      }
    }
  }
  .table__cell_rows{
    display: grid;
    grid-gap: 5px;
    border: none;
  }
}


@media screen  and (max-width: 760px) {
  .table__row_finance {
    //grid-template-columns: unset;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;

    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 14px;

    padding: 20px;

    .table__cell {
      border-bottom: 1px solid #D7DBEB;
    }

    .table__cell{
      &:last-child{
        border-bottom: none;
      }
    }

  }
}


@media screen  and (max-width: 460px) {
  .table__row_finance {

    padding: 10px;


  }
}