.blog__pointOfArticle{
  @extend %paragraph;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */


  /* Black */

  color: #282C38;
}

.blog__pointOfArticle_decimal
{
  list-style-type: decimal ;
}