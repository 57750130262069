.advantages__advantageContainer {
  height: 206px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .advantages__advantageContainer {
    height: max-content;
  }
}
