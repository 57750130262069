.exampleCustomerPage__text {
  z-index: 1;
  height: 548px;
  max-width: 478px;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 32px;
}

@media screen and (max-width: 1200px) {
  .exampleCustomerPage__text {
    height: max-content;
  }
}
