.instructionBlock {
  display: grid;
  grid-template-rows: 600px 506px;
  grid-gap: 20px;

  padding-bottom: 138px;
}

@media screen and (max-width: 1200px) {
  .instructionBlock {
    justify-content: center;
    //justify-items: center;
    display: grid;
    grid-template-rows: unset;
    grid-template-columns: repeat(auto-fit, minmax(auto, 416px));

    padding-bottom: 20px;
  }
}
@import "__laptop/instructionBlock__laptop";
@import "__title/instructionBlock__title";
@import "__laptopImg/instructionBlock__laptopImg";

@import "__manuals/instructionBlock__laptopImg";
@import "__manual/instructionBlock__manual";

.instructionBlock__icon {
  background-size: cover;
  width: 74px;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .instructionBlock__icon {
    background-size: cover;
    width: 54px;
    height: 100%;
  }
}

.instructionBlock__icon_player {
  background-image: url("/landing/instruction/video-play.svg");
}

.instructionBlock__icon_instruction {
  background-image: url("/landing/instruction/document-text.svg");
}
.instructionBlock__textManual {
  display: grid;
  grid-template-columns: minmax(auto, 305px);
  grid-template-rows: max-content max-content;
  grid-gap: 34px;
}

@media screen and (max-width: 1200px) {
  .instructionBlock__textManual {
    grid-gap: 24px;
  }
}

.instructionBlock__linkAtManual {
  position: relative;

  @extend %paragraph;
  font-size: 16px;
  line-height: 130%;
  color: #3d6be2;

  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px;
  align-items: center;

  &:before {
    position: relative;
    content: "";
    width: 16px;
    height: 16px;
    background-size: cover;
  }
}

.instructionBlock__linkAtManual_video {
  &:before {
    background: url("/landing/instruction/play.svg") center center no-repeat;
  }
}
.instructionBlock__linkAtManual_instruction {
  &:before {
    background: url("/landing/instruction/import.svg") center center no-repeat;
  }
}

.instructionBlock__manualTitle {
  @extend %paragraph;
  font-size: 34px;
  max-width: 305px;
  line-height: 110%;
}

@media screen and (max-width: 1200px) {
  .instructionBlock__manualTitle {
    font-size: 18px;
  }

  .instructionBlock__manualTitle_first {
    grid-area: 2;
    font-size: 20px;
  }
}
