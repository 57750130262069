//.header__paragraphLinks{
//  color: #FFFFFF;
//}
.header__paragraphLinks {
  font-size: 14px;
  transition: 0.5s;
  &:hover {
    color: #737a92;
  }
}

.header__paragraphLinks_green {
  color: #3de297;
}

@media screen and (max-width: 1200px) {
  .header__paragraphLinks {
    font-size: 14px;
    margin-bottom: 24px;
    justify-content: space-between;
    align-items: center;
  }
  .header__paragraphLinks_popup {
    margin-bottom: 0;
  }
}
