.functionsAndRates {
  position: relative;
  height: max-content;
  //height: 2308px;
  background: #f1f4f4;
  display: flex;
  flex-direction: column;
}

.functionsAndRates__figureBottom,
.functionsAndRates__figureTop {
  position: absolute;
  background: url("/landing/functionsAndRates/figure.svg") center center
    no-repeat;
  background-size: contain;
  width: 1013.36px;
  height: 790.78px;
  bottom: 0;
  left: -500px;
}

.functionsAndRates__figureTop {
  top: -200px;
  transform: rotate(90deg);
}

.functionsAndRates__figureBig {
  position: absolute;
  background: url("/landing/functionsAndRates/figureBig.png") center center
    no-repeat;
  background-size: contain;
  width: 2469.88px;
  height: 1926.96px;
  margin: auto;
  bottom: 0;
  top: 0;
  left: 0px;
  z-index: -1;
}

.instruction__figureBig {
  position: absolute;
  background: url("/landing/functionsAndRates/figureBig.png") center center
    no-repeat;
  background-size: contain;
  transform: rotate(14deg);
  width: 2069.88px;
  height: 1626.96px;
  margin: auto;
  bottom: 0;
  top: 0;
  left: 300px;
}

.functionsAndRates__welcomeText {
  margin-top: 80px;
  margin-bottom: 80px;
  display: grid;
  grid-template-rows: 58px 50px;
  grid-template-columns: minmax(auto, 570px);
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  grid-gap: 32px;
  text-align: center;
}

@import "__infoBlocks/functionsAndRates__infoBlocks";
@import "__paymentInformation/functionsAndRates__paymentInformation";
@import "__savingTime/functionsAndRates__savingTime";
@import "__description/functionsAndRates__description";

.functionsAndRates__paymentDescription {
  z-index: 2;
  height: 100%;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  display: grid;
  grid-gap: 44px;
}

@media screen and (max-width: 1200px) {
  .functionsAndRates__paymentDescription {
    grid-gap: 28px;
  }
}

.functionsAndRates__commissionInfo {
  font-size: 14px;
  padding: 8px 16px;
  background: linear-gradient(90.76deg, #3f5fd8 -2.51%, #43225c 107.98%);
  border-radius: 10px;
  height: max-content;
}

@import "__paymentExamples/functionsAndRates__paymentExamples";

.functionsAndRates__subscriptionsContent {
  position: relative;
  min-height: 530px;

  padding-top: 168px;

  display: grid;
  grid-template-rows: max-content minmax(160px, max-content);
  grid-gap: 48px;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.functionsAndRates__subscriptionsTitle {
  max-width: 800px;
  text-align: center;
}

.functionsAndRates__subscriptions {
  z-index: 2;
  height: 100%;
  display: grid;

  grid-template-columns: repeat(2, 380px);
  grid-template-rows: repeat(2, 160px);
  grid-gap: 20px;
}

@media screen and(max-width: 1200px) {
  .functionsAndRates__subscriptionsContent {
    padding-top: 130px;
  }
  .functionsAndRates__subscriptions {
    width: 100%;
    justify-content: center;
    grid-auto-rows: 130px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 300px));
    grid-gap: 20px;
  }
}

.functionsAndRates__subscription {
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 34px 44px -10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  position: relative;
  padding: 27.5px 36px 27.5px 36px;

  display: grid;
  grid-template-rows: max-content max-content max-content;
  &:before {
    position: absolute;
    content: "";
    height: 100%;
    right: 0;
    top: 0;
  }
}

.functionsAndRates__word {
  position: absolute;
  height: 100%;
  width: max-content;
  font-size: 250px;
  font-weight: bold;
  line-height: 50%;
  color: #ffffff;
  opacity: 0.1;
  left: 60%;
  top: 0;
}

@media screen and (max-width: 1200px) {
  .functionsAndRates__word {
    font-size: 200px;
  }
}

.functionsAndRates__subscription_min {
  background: linear-gradient(134.66deg, #e7c344 2.5%, #e76b44 109.38%), #ffffff;
}
.functionsAndRates__subscription_standart {
  background: linear-gradient(134.66deg, #65e744 2.5%, #44ace7 109.38%), #ffffff;
}

.functionsAndRates__subscription_max {
  background: linear-gradient(
      134.66deg,
      #4468e7 2.5%,
      #9f44e7 49.82%,
      #e74461 109.38%
    ),
    #ffffff;
}

.functionsAndRates__subscription_ultra {
  background: linear-gradient(
      134.66deg,
      #4468e7 2.5%,
      #0eaf98 49.82%,
      #38e59abf 109.38%
    ),
    #ffffff;
}

.functionsAndRates__subscriptionTitle {
  font-size: 32px;
}

@media screen and (max-width: 1200px) {
  .functionsAndRates__subscription {
    padding: 18px 20px;
  }
  .functionsAndRates__subscriptionTitle {
    font-size: 24px;
  }
}

.functionsAndRates__subscriptionPercent {
  justify-self: end;
}

.functionsAndRates__subscriptionLimitations {
  font-weight: bold;
  font-size: 14px;
}
.functionsAndRates__subscriptionTurnover {
  font-size: 13px;
  color: #ffffff;
  opacity: 0.7;
}
