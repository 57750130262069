.example__grid {
  bottom: 0;
  height: max-content;
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-rows: max-content;
  margin-top: 500px;
}

@media screen and (max-width: 1200px) {
  .example__grid {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 60px;
  }
}

@media screen and (max-width: 650px) {
  .example__grid {
  }
}

@media screen and (max-width: 540px) {
  .example__grid {
    //grid-template-columns: repeat(auto-fit, 355px);
    grid-template-rows: max-content max-content max-content;
    grid-template-columns: 1fr;
    grid-gap: 60px;
    width: auto;
    left: 20px;
    right: 20px;
  }
}
