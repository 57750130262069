.paragraph_color_black {
  color: #282c38;
}
%paragraph_color_black {
  color: #282c38;
}

.paragraph_color_white {
  color: #ffffff;
}

.personal_color_link {
  color: #3d6be2;
  cursor: pointer;
}
