.FAQ__answer {
  -webkit-transition: max-height 0.8s ease-out;
  -moz-transition: max-height 0.8s ease-out;
  -ms-transition: max-height 0.8s ease-out;
  -o-transition: max-height 0.8s ease-out;
  transition: max-height 0.8s ease-out;
  overflow: hidden;
  padding: 0;
  max-height: 0;
}
