.accordance__img {
  background-image: url("/landing/main/accordance/shild.png");
  background-position: left bottom ;
  background-repeat: no-repeat;
  width: 800px;
  height: 497px;
  background-size: contain;
}

.accordance__img_ru {
  background-image: url("/landing/main/accordance/shild_ru.png");
}

@media screen and (max-width: 1200px) {
  .accordance__img {
    max-width: 300px;
    height: 296px;
    background-size: contain;
  }
}
