.tariffPackage {
  padding-top: 100px;
  height: max-content;
  display: grid;
  grid-template-columns: minmax(auto, 814px);
  grid-template-rows: max-content max-content;
  grid-gap: 32px;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;

  width: 100%;
}

.tariffPackage__package {
  width: 100%;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 24px;
}

.tariffPackage__point {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 16px;
}
@media screen and (max-width: 1200px) {
  .tariffPackage {
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .tariffPackage__point {
    grid-template-columns: max-content auto;
  }
}

@media screen and (max-width: 540px) {
  .tariffPackage {
    padding-top: 80px;
  }
}

.tariffPackage__pointIcon {
  background: url("/landing/functionsAndRates/icons/point.svg") center center
    no-repeat;
  height: 22px;
  width: 22px;
}
