.appealForm__input {
  padding: 16px 10px 16px 24px;
  width: 100%;
  color: #fff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  background: transparent;
  &::-webkit-input-placeholder {
    /* Chrome */
    color: white;
  }
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: white;
    opacity: 0.5;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Firefox 4 - 18 */
    color: white;
    opacity: 1;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
  }

  &:focus {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
  }
}

.appealForm__input_error {
  border: 1px solid #df2323;
  background: rgba(255, 255, 255, 0.1);
}

.appealForm__inputPhone {
  padding-left: 100px;
}
