.modal__authError {
  position: absolute;
  top: calc(100%);
  display: flex;
  width: 100%;
  padding: 5px 0;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .modal__authError {
    position: static;
  }
}
.modal__authErrorIcon {
  background: url("/landing/modalAuth/alert.svg") bottom no-repeat;
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
  margin-right: 8px;
}
