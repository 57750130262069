.advantages__advantage {
  //position: relative;
  width: 100%;
  max-width: 300px;
  height: 100%;
  //border-left: 1px dashed rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  //&:before{
  //  content: "";
  //  position: absolute;
  //  height: 150%;
  //  border-left: 1px dashed rgba(0, 0, 0, 0.4);
  //  mix-blend-mode: screen;
  //  //left: 0;
  //}
}

@media screen and (max-width: 1200px) {
  .advantages__advantage {
    height: auto;
    //margin-bottom: 101px;
    max-width: 230px;
  }
}
