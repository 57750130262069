.example__textAtTop {
  display: grid;
  grid-template-rows: max-content max-content;
  max-width: 373px;
  width: max-content;
  grid-gap: 64px;
  grid-area: 1/1/3/3;
  padding-left: 20px;
}

.example__textAtTop_max {
  max-width: unset;
}

@media screen and (max-width: 1200px) {
  .example__textAtTop {
    grid-area: 1/1/1/1;
    grid-gap: 24px;
  }
}

@media screen and (max-width: 540px) {
  .example__textAtTop {
    grid-area: auto;
    max-width: 280px;
  }
}
