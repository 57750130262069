.blog__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 540px) {
  .blog__header {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-gap: 24px;
  }
}
