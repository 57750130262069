.exampleFreelancersPage__image {
  position: absolute;
  left: 0;
  top: 150px;
  background-image: url("/landing/to-freelancers/mainPageFreel.png");

  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  width: 1180px;
  height: 1983.29px;
}

.exampleFreelancersPage__image_en {
  background-image: url("/landing/to-freelancers/mainPageFreel_en.png");
  background-position: top center;
}

.exampleFreelancersPage__image_uz {
  background-image: url("/landing/to-freelancers/mainPageFreel_uz.png");
  background-position: top center;
}

@media screen and (max-width: 540px) {
  .exampleFreelancersPage__image {
    top: 100px;
    width: 534px;
    height: 897.52px;
  }
}
