.FAQ__point {
  height: auto;
  position: relative;
  background: #f3f6f7;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 24px 32px;
}

.FAQ__point_white {
  background: #ffffff;
}
