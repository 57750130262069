.banner__description {
  width: 100%;
  height: 100%;
  max-width: 636px;
  max-height: 461px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

@media screen and (min-width: 1200px) {
  .banner__description {
    position: relative;
    bottom: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .banner__description {
    position: relative;
    margin-top: 88px;
    max-width: 448px;
    max-height: 316px;
  }
}

@media screen and (max-width: 540px) {
  .banner__description {
    margin-top: 48px;
  }
}
