.ambitiousCompanies {
  position: relative;
  height: 800px;
  //height: 200vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.threeSteps {
  z-index: 2;
  font-size: 80px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  text-align: center;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .ambitiousCompanies {
    height: 1566px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 540px) {
  .ambitiousCompanies {
    height: 1150px;
  }
}

@import "__description/ambitiousCompanies__content";
@import "__arm/ambitiousCompanies__arm";

.fixed {
  display: flex;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
}

@media screen and (max-width: 1200px) {
  .ambitiousCompanies__paragraph {
    text-align: center;
  }
}
