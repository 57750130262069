.FAQ {
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 540px) {
}

.FAQ__content {
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@import "__info/FAQ__info";
@import "__point/FAQ__point";
@import "__question/FAQ__question";
@import "__buttonForAnswer/FAQ__buttonForAnswer";
@import "__answer/FAQ__answer";
@import "__answerText/FAQ__answerText";
