.functionsAndRates__paymentInformation {
  background-color: #ffffff;
  box-shadow: 0px 64px 174px -50px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  display: flex;
  justify-content: space-between;
}

.functionsAndRates__paymentInformation {
  position: relative;
  overflow: hidden;

  padding: 65px 77px 89px 109px;

  &:before {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 100%;
    width: 681px;
    background-image: url("/landing/functionsAndRates/blockForPaymentInformation.svg");
    background-size: contain;
  }
}

@media screen and(max-width: 1200px) {
  .functionsAndRates__paymentInformation {
    padding: 45px 20px;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr max-content;
    grid-gap: 59px;
    justify-content: center;
  }

  .functionsAndRates__exampleOfChat {
    width: 235.69px;
    height: 201px;
  }
}

@media screen and(max-width: 600px) {
  .functionsAndRates__paymentInformation {
    padding: 45px 20px;
    display: grid;
    justify-items: center;
    grid-template-rows: max-content max-content;
    grid-template-columns: 1fr;
    grid-gap: 59px;
    justify-content: center;
  }

  .functionsAndRates__exampleOfChat {
    width: 235.69px;
    height: 201px;
  }

  .functionsAndRates__paymentDescription {
    grid-area: 2 / 1/ 2/ 1;
  }
}
