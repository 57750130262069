.table__row_payments, .table__head_payments {
  grid-template-columns:
          50px
          minmax(75px, 1fr)
          minmax(120px, 1fr)
          minmax(120px, 1fr)
          minmax(120px, 1fr)
          1fr
          90px
}


@media screen  and (max-width: 760px) {
  @media screen and (max-width: 760px) {
    .table__row_payments {
      //grid-template-columns: unset;
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;

      background: #FFFFFF;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
      border-radius: 14px;

      padding: 20px;

      .table__cell {
        border-bottom: 1px solid #D7DBEB;
      }

      .table__cell_status {
        border-bottom: none;
      }

    }
  }
}