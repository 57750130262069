.example__iceberg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.example__iceberg_customers {
  height: 1350px;
}
.example__iceberg_freelancer {
  height: 958px;
}

@media screen and (max-width: 1200px) {
  .example__iceberg_customers {
    height: 1900px;
  }
  .example__iceberg_freelancer {
    height: 1000px;
  }
}

@media screen and (max-width: 540px) {
  .example__iceberg_freelancer {
    height: 1300px;
  }
}
