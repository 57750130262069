.appealForm {
  position: relative;
  height: 924px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .appealForm {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 1039px;
  }
}

@media screen and (min-width: 440px) {
  .appealForm__underText {
    text-align: center;
  }
}
@import "__content/appealForm__content";
@import "__form/appealForm__form";
@import "__description/appealForm__description";
@import "__textForm/appealForm__textForm";
@import "__field/appealForm__field";
@import "__input/appealForm__input";
@import "__approval/appealForm__approval";
@import "__phone/appealForm__phone";
@import "__centerText/appealForm__centerText";
@import "__modal/appealForm__modal";
