.exampleOfRegistration {
  z-index: 2;
  height: 598px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exampleOfRegistration__content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 1119px;
}

@media screen and (max-width: 1200px) {
  .exampleOfRegistration__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-gap: 76px;
  }
}

@media screen and (max-width: 600px) {
  .exampleOfRegistration__content {
    grid-template-columns: unset;
    display: grid;
    grid-template-rows: max-content max-content;
    justify-content: center;
    justify-items: center;
    grid-gap: 76px;
  }
}

.exampleOfRegistration__text {
  max-width: 400px;
  display: grid;
  grid-gap: 32px;
}

.exampleOfRegistration__image {
  background-image: url("/landing/to-freelancers/exampleOfRegistration.png");
  background-repeat:    no-repeat;
  background-position: center center;
  background-size: cover;
  width: 529px;
  height: 370px;
  box-shadow: 0px 44px 84px -10px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 0px 34px rgba(0, 0, 0, 0.05));
}

.exampleOfRegistration__image_en{
  background-image: url("/landing/to-freelancers/exampleOfRegistration_en.png");
}
.exampleOfRegistration__image_uz{
  background-image: url("/landing/to-freelancers/exampleOfRegistration_uz.png");
}

@media screen and (max-width: 1200px) {
  .exampleOfRegistration__image {
    grid-area: 1;
    width: 280px;
    height: 195px;
    max-width: 100%;
  }
}
