.paragraph_theme_firstBlock {
  font-size: 18px;
}

.paragraph_theme_warning {
  color: #df2323;
  font-size: 12px;
}

.paragraph_theme_warning_big{
  color: #df2323;
}
.paragraph_theme_success {
  color: #5ccf69;
  font-size: 12px;
}
.paragraph_theme_auth {
  color: #737a92;
}
