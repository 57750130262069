.companyDescription__howWast {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .companyDescription__howWast {
    display: grid;
    grid-gap: 33px;
  }
}
