.uncheckMark {
  cursor: pointer;
  border: 1px solid #737a92;
  border-radius: 4px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  background-size: contain;
  margin-right: 8px;
}


.checkMark {
  cursor: pointer;
  background: url("/landing/modalAuth/checked.svg") center center no-repeat;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  background-size: contain;
  margin-right: 8px;
}
.uncheckMark:hover {
  border: 1px solid #3de297;
}

@media screen and (max-width: 1100px) {
  .tasks__check{
    display: none;
  }
}
.checkMark_error {
  cursor: pointer;

  border: 1px solid #df2323;
  border-radius: 4px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  background-size: contain;
  margin-right: 8px;
}
