.paragraph {
  font-family: 'Golos-Text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

%paragraph {
  font-family: 'Golos-Text', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #282c38;
  line-height: 140%;
}

@media screen and (max-width: 1200px) {
  .paragraph {
    font-size: 16px;
  }
}

@media screen and (max-width: 540px) {
  .paragraph {
    font-size: 14px;
  }
}

@import "_theme/paragraph_theme_link";
@import "_theme/paragraph_theme";
@import "_color/paragraph_color";
@import "_weight/paragraph_weight";

/// мусор
.paragraph_size14 {
  font-size: 14px;
}

.paragraph_button {
  font-family: Golos-Text, sans-serif;
  font-weight: 400;
  font-size: 18px;
}
