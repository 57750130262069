.appealForm__modal {

  position: absolute;

  margin: auto;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;

  width: 446px;
  height: max-content;

  background: #ffffff;
  box-shadow: 0px 24px 90px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  padding: 40px 45px;

  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
}

.appealForm__modal_show {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.appealForm__modal_top {
  transition: 0.3s top;
  top: 0;
}
.appealForm__modal_close {
  display: none;
}

.appealForm__modalTitle {
  font-size: 20px;
}


.appealForm__modalMT20 {
  margin-top: 20px;
}

.appealForm__modalParagraph {
  font-size: 16px;
  text-align: center;
}

.appealForm__closeModal {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  background: url("/landing/appealForm/closeModal.svg") center center;
  height: 12px;
  width: 12px;
  background-size: contain;
}


.appealForm__modalContacts{
  width: 100%;
  display: grid;
  grid-gap: 5px;
  align-items: start;
}
.appealForm__modalContact{
  @extend %paragraph;
  color: #282c38;
  font-size: 16px;
  text-align: center;
}