.header__signIn {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 40px;
  width: max-content;
  height: 48px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  transition: 0.5s;
}
.header__signIn_black {
  background: #f1f4f4;
}

.header__signIn:hover {
  border: 1px solid #3de297;
  background: #3de297;
  color: #282c38;
}

@media screen and (max-width: 1200px) {
  .header__signIn {
    //width: 88px;
    height: 34px;
  }
}

@media screen and (max-width: 540px) {
  .header__signIn {
    width: 34px;
    height: 34px;
  }
  .header__signInText {
    display: none;
  }
}
