.exampleCustomerPage__contract {
  z-index: 3;
  position: absolute;
  //width: 100%;
  height: 978px;
  bottom: 0;
  width: auto;
  //padding: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding-top: 150px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.examplePage__iceberg {
  z-index: 2;
  position: absolute;
  height: 978px;
  width: 100%;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 900px) {
  .exampleCustomerPage__contract {
    padding: 50px 40px;
    margin: auto;
    //flex-direction: column;
    //align-items: center;
    display: grid;
    grid-template-rows: max-content max-content;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-content: center;
  }
}
