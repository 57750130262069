.verticalLines {
  position: absolute;
  justify-self: center;
  bottom: 0;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-rows: 100% 0;
  align-items: center;
}
.verticalLines__line {
  width: 100%;
  height: 100%;
  border-left: 1px dashed rgba(0, 0, 0, 0.4);
}
.verticalLines__line_last {
  border-right: 1px dashed rgba(0, 0, 0, 0.4);
}
@media screen and (min-width: 1200px) {
  .verticalLines__line_hideBefore1200 {
    border: none;
  }
}
@media screen and (max-width: 1200px) {
  .verticalLines__line_hideAfter1200 {
    display: none;
  }
  .verticalLines {
    //left: 80px;
    //grid-template-columns: repeat(auto-fit, 355px);
    grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  }

  .verticalLines_team {
    grid-template-columns: repeat(3, minmax(250px, 300px));
  }

  .verticalLines__line_last {
    display: none;
  }
}
@media screen and (max-width: 710px) {
  .verticalLines {
    grid-template-columns: repeat(auto-fit, 250px);
  }
}
@media screen and (max-width: 540px) {
  .verticalLines {
    //left:20px;
    width: 100%;
    max-width: unset;
    grid-template-columns: repeat(auto-fit, 140px);
  }
  .verticalLines__line_mobile {
    display: none;
  }

  .verticalLines__line_last {
    display: none;
  }
}
