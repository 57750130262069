.authorization__errorField {
  top: calc(100%);
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 8px;
  width: 100%;
  align-items: center;
  transition: .5s;
}

.authorization__errorField_fixHeight {
  height: 22px;
}
