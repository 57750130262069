.instructionBlock__laptopImg {
  background-size: contain;
  width: 686px;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
}

.instructionBlock__laptopImg_customer {
  background-image: url("/landing/instruction/laptop.png");
}

.instructionBlock__laptopImg_freelancer {
  background-image: url("/landing/instruction/laptop__freelancer.png");
}

@media screen and (max-width: 1200px) {
  .instructionBlock__laptopImg {
    position: relative;
    left: 16px;

    width: 100%;
    height: 100%;
  }
}
