.ambitiousCompanies__arm {
  //display: none;
  position: absolute;
  right: -300px;
  bottom: 0;
  height: 990px;
  width: 1260px;
  //transition: none;
  background-image: url("/landing/main/ambitiousCompanies/armPhoneMax.png") ;
  background-position: right bottom ;
  background-repeat: no-repeat;
  //transition: .5s;
  background-size: cover;
  image-rendering: high-quality;
}

.ambitiousCompanies__arm_en{
  //transition: none;
  background-image: url("/landing/main/ambitiousCompanies/armPhoneMax_en.png");
  //transition: .5s;
  background-size: cover;
}

.fixed_a {
  opacity: 0;
  display: flex;
  position: fixed;
  margin: 0;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: url("/landing/main/ambitiousCompanies/armPhoneMax.png") center
    center no-repeat;
  background-size: cover;
}
.ambitiousCompanies__arm_an {
  position: absolute;
  background: url("/landing/main/ambitiousCompanies/armPhoneMax.png") center
    bottom no-repeat;
  background-size: cover;
  display: block;
  opacity: 1;
  height: 100%;
  width: 1260px;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
  top: 56%;
  left: 65%;
  //left: 71%;
}

@media screen and (max-width: 1100px) {
  .ambitiousCompanies__arm {
    position: relative;
    top: -100px;
    left: 0;
    right: 0;
    height: 1469px;
    width: 1102px;
    background-position: center top;
      background-repeat: no-repeat;
  }
}

@media screen and (max-width: 540px) {
  .ambitiousCompanies__arm {
    top: -50px;
    height: 720px;
    width: 960px;
    background-position: center center ;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
