.bank131-SelfEmployed__frame {
  width: 100%;
  border: 0

}

.selfEmployed__widget {
  height: 450px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selfEmployed__message{
  padding: 0 35px;
  display: flex;
  align-items: center;
}

#bank131-self-employed {
  width: 100%;
  //height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //display: grid;
  //display: -moz-box;
  //align-items: center;
  //justify-content: center;
  //justify-items: center;
  //align-content: center;
  //grid-template-rows: 300px;

  .bank131-SelfEmployed__frame {

    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.bank131-SelfEmployed__frame {
  height: 100%;
}

.bank131-Button__container {

  height: 45px;
  @extend %paragraph;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: none;
  border-radius: 50px;
  //background: #ffffff;
  //
  //padding: 16px 32px;

  width: max-content;

  transition: background 0.5s;
  position: relative;

  padding: 8px 24px;
  color: white;
  background: #282c38;

  &:not(:disabled):hover {
    background: #4c4f5a;
    color: white;
  }
}


.bank131-self-employed {
  .FormField__container{
    background: #5f33db;
  }
}