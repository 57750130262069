.title_size_16 {
  font-size: 16px;
}

.title_size_34 {
  font-size: 34px;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .title_size_34 {
    font-size: 34px;
  }
}
