.FAQ__question {
  width: 100%;
  max-width: 600px;
  min-height: 52px;
  line-height: 140%;
  color: #04103d;
  opacity: 0.7;
  display: flex;
  align-items: center;
  padding-right: 40px;
}
