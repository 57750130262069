.examples__content {
  width: 100%;
  display: grid;
  grid-template-rows: 600px 454px 566px 591px;
  grid-template-columns: 1fr;
  grid-gap: 60px;

  @media screen and (max-width: 1200px) {
    grid-template-rows: repeat(5, max-content);
  }

  @media screen and (max-width: 800px) {
    padding: 0px;
  }
}
