.signUpRequest {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signUpRequest__content {
  overflow: hidden;
  position: relative;
  height: 276px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 64px;
  &:before {
    content: "";
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 130%;
    background: url("/landing/signUpRequest/signUpRequest.png") right center
      no-repeat;
    background-size: cover;
  }
  border-radius: 30px;
}
.signUpRequest__text {
  z-index: 1;
  width: 100%;
  max-width: 582px;
}
.signUpRequest__button {
  //height: 70px;
  //width: 200px;
  min-width: 150px;
  z-index: 1;
  padding: 20px 30px;
}
.signUpRequest__textSelection {
  color: #3de297;
}

@media screen and (max-width: 1200px) {
  .signUpRequest {
    height: 500px;
  }
  .signUpRequest__content {
    height: 356px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    &:before {
      background: url("/landing/signUpRequest/backgroundRotate.png") -50px -400px
        no-repeat;
      background-size: cover;
      //transform: rotate(90deg) ;
      width: 130%;
      height: 100%;
    }
  }
  .signUpRequest__text {
    width: 100%;
  }
  .signUpRequest__paragraph {
    max-width: 289px;
  }
}

@media screen and (max-width: 540px) {
  .signUpRequest__content {
    &:before {
      background: url("/landing/signUpRequest/signUpRequest.png") left bottom
        no-repeat;
      background-size: cover;
      transform: rotate(0deg);
      width: 100%;
      height: 100%;
    }
    padding: 50px 24px;
  }

  .signUpRequest__button {
    width: 100%;
  }
}
