.header__links {
  width: 100%;
  //max-width: 550px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

@media screen and (max-width: 1200px) {
  .header__links {
    padding-top: 70px;
    height: max-content;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.paragraph_header {
  font-family: Golos, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}
