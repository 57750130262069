.iceberg {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  height: 770px;
  width: 100%;
  bottom: 0;
}

.iceberg__advantages {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iceberg__blue {
  background: linear-gradient(354.55deg, #ffffff -31.05%, #d7f0f6 117.57%);
  clip-path: polygon(
    0px 94px,
    6.63% 18.36%,
    13.28% 19.62%,
    24.22% 13.42%,
    33.69% 20.99%,
    50.43% 20.14%,
    64.39% 9.21%,
    81.9% 12%,
    100% 0px,
    100% 100%,
    0px 100%
  );
}

.iceberg__blur {
  backdrop-filter: blur(14px);

  background: linear-gradient(
    179.88deg,
    #ffffff -16.25%,
    rgba(255, 255, 255, 0) 53.19%
  );
  clip-path: polygon(
    0px 238px,
    8.96% 20.79%,
    16.61% 23.62%,
    25.09% 17.59%,
    42.51% 22.14%,
    58.63% 11.65%,
    72.08% 18.83%,
    81.87% 8.1%,
    94.31% 7.76%,
    100.08% 5px,
    100% 100%,
    0px 100%
  );
}

@media screen and(max-width: 1200px) {
  .iceberg__blue {
    clip-path: polygon(
      1px 126px,
      8.72% 20.14%,
      14.3% 16.87%,
      25.09% 12.53%,
      41.66% 15%,
      60.01% 12.78%,
      70.96% 13.61%,
      99.35% 5.06%,
      1303px 90px,
      1421px 103px,
      100% 100%,
      0px 100%
    );
  }

  .iceberg__blur {
    clip-path: polygon(
      0px 94px,
      11.78% 8.32%,
      32.52% 10.48%,
      53.84% 15.46%,
      71.98% 16.4%,
      94.37% 4.45%,
      1224px 139px,
      1367px 41px,
      100% 100%,
      0px 100%
    );
  }
}

@media screen and (max-width: 710px) {
  .iceberg__blue {
    height: 1000px;
    clip-path: polygon(
      1px 126px,
      8.72% 20.14%,
      14.3% 16.87%,
      25.09% 12.53%,
      41.66% 15%,
      60.01% 12.78%,
      70.96% 13.61%,
      99.35% 5.06%,
      1303px 90px,
      1421px 103px,
      100% 100%,
      0px 100%
    );
  }

  .iceberg__blur {
    height: 1000px;
    clip-path: polygon(
      0px 94px,
      11.78% 13.4%,
      26.67% 12.31%,
      53.84% 15.46%,
      66.13% 11.98%,
      99.32% 6.53%,
      1224px 139px,
      1367px 41px,
      100% 100%,
      0px 100%
    );
  }
}

@media screen and (max-width: 580px) {
  .iceberg__blue {
    height: 1500px;
    clip-path: polygon(
      1px 126px,
      8.72% 20.14%,
      14.3% 16.87%,
      25.09% 12.53%,
      41.66% 15%,
      60.01% 12.78%,
      70.96% 13.61%,
      99.35% 5.06%,
      1303px 90px,
      1421px 103px,
      100% 100%,
      0px 100%
    );
  }

  .iceberg__blur {
    height: 1500px;
    clip-path: polygon(
      0px 94px,
      11.78% 13.4%,
      26.67% 12.31%,
      53.84% 15.46%,
      66.13% 11.98%,
      99.32% 6.53%,
      1224px 139px,
      1367px 41px,
      100% 100%,
      0px 100%
    );
  }
}

@media screen and (max-width: 540px) {
  .iceberg__blue {
    height: 1200px;
    clip-path: polygon(
      0 172px,
      33.95% 11.46%,
      81.9% 6.91%,
      100% 8%,
      1303px 90px,
      1421px 103px,
      100% 100%,
      0px 100%
    );
  }

  .iceberg__blur {
    height: 1200px;
    clip-path: polygon(
      0px 94px,
      31.54% 10.24%,
      61.95% 10.06%,
      100% 11.23%,
      1224px 139px,
      1367px 41px,
      100% 100%,
      0px 100%
    );
  }
}

.iceberg_maxHeight {
  height: 100%;
}
