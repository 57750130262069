.title_theme_header {
  font-size: 48px;
  line-height: 120%;
}

@media screen and (max-width: 1200px) {
  .title_theme_header {
    font-size: 32px;
  }
}

@media screen and (max-width: 540px) {
  .title_theme_header {
    font-size: 24px;
  }
}
