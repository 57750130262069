@import "bank131/widget";
@import "bank131/selfemployed-widget";

.onBoarding__button {
  height: 54px;
  align-self: end;
  width: 100%;
}

.onBoarding__button_disabled {
  height: 54px;
  align-self: end;
  background: #B3B7C7;
  cursor: default;
  &:hover {
    background: #B3B7C7;
  }
}