.coupleOfBlocks {
  z-index: 2;
  //position: absolute;
  height: max-content;
  padding-top: 10px;
  padding-bottom: 64px;
  bottom: -150px;
  display: grid;
  //grid-template-columns: 580px 580px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-gap: 20px;
  //&:before {
  //  position: absolute;
  //  content: "";
  //  left: 0;
  //  bottom: 100px;
  //  border-left: 100vw solid transparent;
  //  border-bottom: 100px solid white;
  //}
}

@media screen and (max-width: 600px) {
  .coupleOfBlocks {
    margin: 0 auto;
    //left: 20px;
    //right: 20px;
    bottom: -200px;
    grid-template-columns: minmax(280px, 560px);
    grid-template-rows: 211px 211px;
    justify-content: center;
  }
}

@import "__our/coupleOfBlocks__our";
@import "__title/coupleOfBlocks__title";
