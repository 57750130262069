.functionsAndRates__paymentExamples {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.functionsAndRates__paymentExample {
  position: relative;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 44px 64px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14px);
  border-radius: 10px;

  width: max-content;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding: 12px;
}

.functionsAndRates__paymentTitle {
  line-height: 140%;
  text-align: right;
}
.functionsAndRates__paymentTitle_one {
  font-size: 13.8959px;
}

.functionsAndRates__paymentTitle_two {
  font-size: 22.0925px;
}
.functionsAndRates__paymentTitle_three {
  font-size: 8.51681px;
}

.functionsAndRates__paymentUnderText {
  line-height: 140%;
  color: #737a92;
  text-align: right;
}
.functionsAndRates__paymentUnderText_one {
  font-size: 12.9033px;
}
.functionsAndRates__paymentUnderText_two {
  font-size: 20.5145px;
}
.functionsAndRates__paymentUnderText_three {
  font-size: 7.90847px;
}

@media screen and(max-width: 1200px) {
  .functionsAndRates__paymentExample_1 {
    padding: 9px 15px 9px 15px;
    width: max-content;
    max-width: 125px;
  }
  .functionsAndRates__paymentExample_2 {
    padding: 14px 18px;
    width: max-content;
    max-width: 190px;
    margin-left: auto;
    right: 0;

    margin-top: 25px;
    margin-bottom: 25px;
  }
  .functionsAndRates__paymentExample_3 {
    width: max-content;
    max-width: 76px;
    padding: 5px 7px;
    margin-right: auto;
    left: 37px;
  }

  .functionsAndRates__paymentTitle_one {
    font-size: 8px;
  }

  .functionsAndRates__paymentTitle_two {
    font-size: 12px;
  }
  .functionsAndRates__paymentTitle_three {
    font-size: 4px;
  }

  .functionsAndRates__paymentUnderText {
    line-height: 140%;
    color: #737a92;
    text-align: right;
  }
  .functionsAndRates__paymentUnderText_one {
    font-size: 7px;
  }
  .functionsAndRates__paymentUnderText_two {
    font-size: 11px;
  }
  .functionsAndRates__paymentUnderText_three {
    font-size: 4px;
  }
}
