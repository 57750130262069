.header__signInIcon {
  background: url("/landing/header/arrow__login.svg") center right no-repeat;
  height: 18px;
  min-width: 25px;
}

.header__signInIcon_black {
  background: url("/landing/header/arrow__login_black.svg") center right
    no-repeat;
  height: 18px;
  width: 25px;
}

@media screen and (max-width: 540px) {
  .header__signInIcon {
    background: url("/landing/header/login.svg") center center no-repeat;
    height: 24px;
    min-width: 24px;
  }

  .header__signInIcon_black {
    background: url("/landing/header/logIn_black.svg") center center no-repeat;
    height: 24px;
    width: 24px;
  }
}
