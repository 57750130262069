.about {
}

.about__whiteBlock {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  background: white;
  clip-path: polygon(0 100px, 100% 0px, 100% 100%, 0px 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
@import "companyDescription/companyDescription";
@import "ourTeam/ourTeam";
@import "coupleOfBlocks/coupleOfBlocks";
