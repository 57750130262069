.header__paragraphMenu {
  font-family: Golos Text, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  //color: #FFFFFF;
}

@media screen and (max-width: 1200px) {
  .header__paragraphMenu {
    font-size: 12px;
  }
  .paragraph_theme_headerLinks {
    font-size: 14px;
    margin-bottom: 24px;
  }
}
