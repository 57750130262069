.exampleCustomerPage {
  //position: relative;
  height: 1658px;
}

@import "__page/exampleCustomerPage__page";
@import "__image/exampleCustomerPage__image";
@import "__contract/exampleCustomerPage__contract";
@import "__mainText/exampleCustomerPage__mainText";
@import "__underText/exampleCustomerPage__underText";
@import "__paragraph/exampleCustomerPage__paragraph";
@import "__folder/exampleCustomerPage__folder";
@import "__text/exampleCustomerPage__text";
