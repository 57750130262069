@media screen and (min-width: 1200px) {
  .header__buttonMenu {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .header__buttonMenu {

    max-height: 100vh;
    cursor: pointer;
    padding: 5px 16px 5px 5px;
    width: max-content;
    height: 34px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    transition: .5s background, color, border, height;
    .paragraph {
      transition: .5s color;
    }
    &:hover {
      border: 1px solid #3de297;
      background: #3de297;
    }
    &:hover .paragraph {
      color: #282c38;
    }
    &:hover .header__buttonMenuBurgerSticks{
      background: #282c38;
      &:after, &:before {
        background: #282c38;
      }
    }
  }
  .header__buttonMenu_black {
    background: #f1f4f4;
  }
  .header__buttonMenuBurger {
    position: relative;
    margin-right: 10px;
    min-height: 24px;
    min-width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header__buttonMenuBurgerSticks {
    width: 18px;
    height: 2px;
    background: #ffffff;
    transition: .5s background;
    &:before {
      position: absolute;
      content: "";
      background: #ffffff;
      width: 10px;
      height: 2px;
      bottom: 5px;
      left: 6px;
      transition: .5s background;
    }
    &:after {
      position: absolute;
      content: "";
      background: #ffffff;
      width: 10px;
      height: 2px;
      top: 5px;
      right: 6px;
      transition: .5s background;
    }
  }
}
.header__buttonMenuBurgerSticks_black {
  background: #282c38;
  &:before {
    background: #282c38;
  }
  &:after {
    background: #282c38;
  }
}

@media screen and (max-width: 540px) {
  .header__buttonMenu {
    height: 34px;
    width: 34px;
  }
  .header__buttonMenuParagraph {
    display: none;
  }
}
