.void {
  z-index: 9;
  display: none;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.void_openWithBackground {
  display: block;
  background: #000000a3;
}

.void__openWithoutBackground {
  display: block;
}
