.ourTeam {
  position: relative;
  padding-top: 10px;
  //height: 1600px;
  padding-bottom: 156px;
  display: grid;
  grid-gap: 140px;
  grid-template-columns: 100%;
}

@media screen and (max-width: 1200px) {
  .ourTeam {
    padding-top: 10px;
    grid-template-columns: 100%;
  }
}
.ourTeam__teamPhotoContainer {
  z-index: 2;
  background-color: #cfc9c9;
  border-radius: 30px;
  position: relative;
  max-width: 100%;
  height: 700px;
  padding: 62px 60px;
  //display: none;
  grid-template-columns: minmax(auto, 480px);
}
.ourTeam__teamPhoto {
  position: absolute;
  z-index: 1;

  //background-image: url("/landing/contacts/dreamTeam.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;

  width: 100%;
  height: 100%;

  box-shadow: 0px 55px 104px -20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;

  padding: 62px 60px;

  bottom: 0;
}
.ourTeam__teamText {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .ourTeam__teamPhotoContainer {
    overflow: hidden;
    width: 100%;
    max-width: 560px;
    height: 524px;
    padding: 20px;
    justify-self: center;
  }
  .ourTeam__teamPhoto {
    z-index: 0;
    position: absolute;
    top: 0;
    //width: 741px;
    //height: 494px;
    width: 100%;
    height: 100%;
    //transform: translate(-25%, -60%);
    background-size: contain;
    background-position: center bottom;
  }
}

.ourTeam__team {
  position: relative;
  display: grid;
  width: 100%;
  z-index: 1;

  grid-template-rows: max-content max-content;
  grid-gap: 100px;
}

.ourTeam__lines {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ourTeam__teamTitle {
  justify-self: center;
}

.ourTeam__teammates {
  position: absolute;
  width: 100vw;
  max-width: 1200px;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-row-gap: 80px;
  align-items: center;
  bottom: 0;
}

.ourTeam__teammates_void {
  position: relative;
}

@media screen and (max-width: 1200px) and (min-width: 900px) {
  .ourTeam__teammates {
    grid-template-columns: repeat(3, minmax(250px, 300px));
  }
}

@media screen and (max-width: 900px) and (min-width: 710px) {
  .ourTeam__teammates {
    grid-template-columns: repeat(3, minmax(250px, 300px));
  }
}

@media screen and (max-width: 710px) {
  .ourTeam__teammates {
    width: 100%;
    max-width: unset;
    grid-template-columns: repeat(auto-fit, 250px);
  }
}

@media screen and (max-width: 540px) {
  .ourTeam__teammates {
    grid-template-columns: repeat(auto-fit, 140px);
  }
}

.ourTeam__teammate {
  grid-gap: 40px;
  display: grid;
}

.ourTeam__teammate_void {
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .ourTeam__teammate {
    grid-gap: 24px;
    display: grid;
  }
}

.ourTeam__teammateAvatar {
  margin-left: 20px;
  height: 100px;
  width: 100px;
  object-fit: contain;
}

@media screen and (max-width: 540px) {
  .ourTeam__teammateAvatar {
    height: 60px;
    width: 60px;
    margin-left: 10px;
  }
}

.ourTeam__teammateInfo {
  display: grid;
  grid-template-rows: max-content max-content;

  padding-left: 20px;
  border-left: 1px solid #282c38;
}

@media screen and (max-width: 540px) {
  .ourTeam__teammateInfo {
    padding-left: 10px;
    grid-gap: 8px;
  }
}

.ourTeam__teammateName {
  @extend %paragraph;
  font-size: 16px;
}

@media screen and (max-width: 540px) {
  .ourTeam__teammateName {
    font-size: 14px;
  }
}

.ourTeam__teammatePost {
  @extend %paragraph;
  font-size: 16px;
  color: #b3b7c7;
}

@media screen and (max-width: 540px) {
  .ourTeam__teammatePost {
    font-size: 12px;
  }
}
