﻿.combinedBlocks {
  width: 100%;
  display: grid;
}

.combinedBlocks__container {
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
}

.combinedBlocks__blueBlock {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: max-content;
}

.combinedBlocks__iceberg {
  height: max-content;
  bottom: 0;
  left: 0;
  width: 100%;
}

.combinedBlocks__icebergStone {
  margin: auto;

  left: 0;
  right: 0;
  height: max-content;
  width: 100%;
  bottom: 0;
  display: grid;
  justify-items: center;
}

@media screen and( max-width: 540px) {
  .combinedBlocks__icebergStone {
  }
}

.combinedBlocks__icebergStone_blue {
  position: absolute;
  height: 100%;
  padding-top: 0%;
}

.combinedBlocks__icebergStone_blur {
  backdrop-filter: blur(14px);

  background: linear-gradient(
    179.88deg,
    #ffffff -16.25%,
    rgba(255, 255, 255, 0) 53.19%
  );
  clip-path: polygon(
    0px 238px,
    8.96% 20.79%,
    16.61% 23.62%,
    25.09% 17.59%,
    42.51% 22.14%,
    58.63% 11.65%,
    72.08% 18.83%,
    81.87% 8.1%,
    94.31% 7.76%,
    100.08% 5px,
    100% 100%,
    0px 100%
  );
}

.combinedBlocks__icebergStone_maxHeight {
  height: 100%;
}
