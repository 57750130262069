.companyDescription__our {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  box-shadow: 0px 55px 104px -20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;

  padding: 50px 60px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .companyDescription__our {
    padding: 20px;
  }
}

.companyDescription__us_target {
  background-image: url("/landing/about/usTarget.png");
}

.companyDescription__us_mission {
  background-image: url("/landing/about/usMission.png");
}

.companyDescription__ourParagraph {
  font-size: 20px;
  max-width: 361px;
}

@media screen and (max-width: 1200px) {
  .companyDescription__ourParagraph {
    font-size: 14px;
  }
}

.companyDescription__usTargetParagraph,
.companyDescription__usMissionParagraph {
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  .companyDescription__usTargetParagraph,
  .companyDescription__usMissionParagraph {
    font-size: 14px;
  }
}

.companyDescription__usMissionParagraph {
  max-width: 361px;
}
