.optimization {
  position: relative;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .optimization {
    height: 624px;
  }
}

@media screen and (max-width: 540px) {
  .optimization {
    padding-top: 100px;
    height: 753px;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

@import "__description/optimization__description";
@import "__img/optimization__img";
@import "__relevance/optimization__relevance";
