.blog__newsDate {
  @extend %paragraph;
  font-size: 12px;
  line-height: 140%;
  color: #737a92;
}

@media screen and (min-width: 1200px) {
  .blog__newsDate_first {
    font-size: 14px;
  }
}
