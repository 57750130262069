.blog__pill {
  padding: 8px 16px;
  background: rgba(95, 51, 219, 0.2);
  border-radius: 30px;

  @extend %paragraph;

  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #5f33db;
}

@media screen and (max-width: 540px) {
  .blog__pill {
    padding: 8px 16px;

    font-size: 12px;
  }
}
