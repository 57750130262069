.blog__newsParagraph {
  margin-top: 24px;
  @extend %paragraph;
  font-size: 14px;
  line-height: 140%;
  color: #737a92;

  max-height: 80px;

  overflow: hidden;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1200px) {
  .blog__newsParagraph_first {
    font-size: 14px;
  }
}
