.companyDescription__pill {
  width: max-content;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: rgba(51, 219, 148, 0.2);
  border-radius: 30px;

  @extend %paragraph;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #33db94;
}

@media screen and (max-width: 540px) {
  .companyDescription__pill {
    font-size: 12px;
  }
}
