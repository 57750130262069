.FAQ__buttonForAnswer {
  border: none;
  transition: 0.5s;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  z-index: 1;
  position: absolute;
  right: 32px;
  margin: auto;
  top: 0px;
  bottom: 0;
  cursor: pointer;
  height: 34px;
  background: white;
  width: 34px;
  border-radius: 50%;

  &:before {
    position: absolute;
    content: "";
    background: url("/landing/main/FAQ/faqOpen.svg") no-repeat;
    height: 50%;
    width: 50%;
    margin: auto;
    background-size: cover;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: 0.5s;
  }
  &:hover {
    &:before {
      background: url("/landing/main/FAQ/croos_green.svg") no-repeat;
      height: 50%;
      width: 50%;
      background-size: cover;
    }
  }
}

.FAQ__buttonForAnswer:checked + .FAQ__answer {
  max-height: 300px;
  -webkit-transition: max-height 1s linear;
  -moz-transition: max-height 1s linear;
  -ms-transition: max-height 1s linear;
  -o-transition: max-height 1s linear;
  transition: max-height 1s linear;
  //padding-bottom: 20px;
}

.FAQ__buttonForAnswer:checked {
  &:before {
    transform: rotate(45deg);
  }
}
