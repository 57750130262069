.appealForm__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media screen and (max-width: 800px) {
  .appealForm__form {
    align-items: center;
  }
}
