.container_background_banner {
  overflow: hidden;
  background: #0f153b;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background: url("/landing/main/banner/banner_background.png") top center
      no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
  }
}
