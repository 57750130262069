.instructionBlock__laptop {
  background: #ffffff;
  border-radius: 30px;

  padding: 100px 0 100px 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .instructionBlock__laptop {
    width: 100%;
    //padding: 45px 20px 24px 20px;
    padding: 20px 20px 24px 20px;

    display: grid;

    height: 280px;

    //grid-template-rows: 154px max-content;
    grid-template-rows: 1fr max-content;

    grid-gap: 50px;

    justify-content: center;
    align-items: center;
    //
    //height: max-content;
  }
}
