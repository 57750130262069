.ambitiousCompanies__content {
  position: relative;
  bottom: 250px;
  //position: fixed;
  //left: 160px;
  //bottom: 100px;
  //opacity: 0;
  //position: fixed;
  //margin: auto;
  //left: 0;
  ////transform: translate(-50%, -50%);
  ////top: 50%;
  ////left: 30%;
  width: 400px;
  height: 412px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.ambitiousCompanies__content_an {
  position: relative;
  bottom: 250px;
  left: 0;
  opacity: 1;
}

@media screen and (max-width: 1100px) {
  .ambitiousCompanies__content {
    position: static;
    height: 306px;
  }
}
@media screen and (max-width: 540px) {
  .ambitiousCompanies__content {
    width: 100%;
    height: 366px;
    max-width: 240px;
  }
}
