.faq {
  height: max-content;
  padding-bottom: 146px;
  //height: 1400px;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-row-gap: 90px;
}

.faq__content {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-row-gap: 80px;
  justify-items: center;
}

.faq__text {
  display: grid;
  grid-gap: 16px;
  align-items: center;
  justify-items: center;
}

.faq__pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: max-content;

  background: rgba(51, 88, 219, 0.2);
  border-radius: 30px;

  @extend %paragraph;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #3358db;
}

@media screen and (max-width: 1200px) {
  .faq__pill {
    font-size: 12px;
  }
}
