.earth {
  z-index: 2;
  height: 790px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.earth__content {
  max-width: 480px;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 32px;
}

@media screen and (max-width: 540px) {
  .earth {
    height: max-content;
    padding-top: 78px;
    padding-bottom: 78px;
  }
}
