.blog__newsTitle {
  margin-top: 16px;
  @extend %paragraph;
  font-size: 24px;
  line-height: 120%;
  color: #282c38;
}

@media screen and (min-width: 1200px) {
  .blog__newsTitle_first {
    font-size: 16px;
  }
}


@media screen and (max-width: 1200px) {
  .blog__newsTitle_first {
    font-size: 16px;
  }
}