@font-face {
  font-family: "Golos-Text";
  src: local("Golos Text Regular"), local("GolosText-Regular"),
    url("Golostextregular.woff2") format("woff2"),
    url("Golostextregular.woff") format("woff"),
    url("Golostextregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GolosText-DemiBold";
  src: local("Golos Text DemiBold"), local("GolosText-DemiBold"),
    url("Golostextdemibold.woff2") format("woff2"),
    url("Golostextdemibold.woff") format("woff"),
    url("Golostextdemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Golos-Text";
  src: local("Golos Text Black"), local("GolosText-Black"),
    url("Golostextblack.woff2") format("woff2"),
    url("Golostextblack.woff") format("woff"),
    url("Golostextblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Golos-Text-Medium";
  src: local("Golos Text Medium"), local("GolosText-Medium"),
    url("Golostextmedium.woff2") format("woff2"),
    url("Golostextmedium.woff") format("woff"),
    url("Golostextmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Golos-Text";
  src: local("Golos Text Bold"), local("GolosText-Bold"),
    url("Golostextbold.woff2") format("woff2"),
    url("Golostextbold.woff") format("woff"),
    url("Golostextbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}


