.modal {
  z-index: 9;
  position: fixed;

  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: scroll;

  background: #00000080;

  &::-webkit-scrollbar {
    /* chrome based */
    width: 0px; /* ширина scrollbar'a */
    height: 0;
    background: transparent; /* опционально */
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  transition: visibility 0.5s, opacity 0.5s;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

}

.modal_open {
  visibility: visible;
  opacity: 1;
  pointer-events: unset;
}

.modal__body {
  height: max-content;

  position: relative;

  padding: 60px 60px 43px 60px;
  background: #ffffff;
  border-radius: 20px;

  display: grid;
  grid-template-columns: minmax(100%, 360px);
  grid-template-rows: max-content max-content;
  grid-gap: 32px;
}

@media screen and (max-width: 600px) {
  .modal__body {
    padding: 30px;
  }
}

.modal__title {
  @extend %paragraph;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #282c38;
}
.modal__content {
  display: grid;
  grid-gap: 40px;
}

.modal__form {
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 32px;
}

.modal__input {
  width: 100%;
  height: 54px;
}

.modal__nuance {
  @extend %paragraph;
  font-size: 14px;
  line-height: 150%;
  color: #737a92;
}

.modal__inputWrapper {
  position: relative;
}

.modal__textAreaWrapper {
  position: relative;
  height: 132px;
}

.modal__textArea {
  width: 100%;
  height: 100%;
  resize: none;
}

.modal__button {
  height: 54px;
  width: 100%;
}
