.advantages__paragraph {
  position: relative;
  padding: 0 22px;
  border-left: 1px solid #282c38;
}

@media screen and (max-width: 1200px) {
  .advantages__paragraph {
    padding: 0 0 0 22px;
  }
}
