.exampleWithdrawal {
  justify-self: center;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.exampleWithdrawal__content {
  width: 100%;
  max-width: 1111px;

  height: 365px;

  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .exampleWithdrawal__content {
    padding-bottom: 40px;
    padding-top: 40px;
    height: max-content;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 48px;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 700px) {
  .exampleWithdrawal__content {
    grid-template-columns: unset;
    padding-bottom: 40px;
    padding-top: 40px;
    height: max-content;
    display: grid;
    grid-template-rows: max-content max-content;
    grid-gap: 48px;
    justify-items: center;
    justify-content: center;
  }
}

.exampleWithdrawal__example {
  position: relative;
  width: 500px;
  height: 365px;
}
@media screen and (max-width: 1200px) {
  .exampleWithdrawal__example {
    width: 280px;
    max-width: 100%;
    height: 204.4px;
  }
}

.exampleWithdrawal__chart {
  position: absolute;
  bottom: 0;
  left: 47px;

  background-image: url("/landing/to-freelancers/chart.png") ;
  background-position: center center ;
  background-repeat: no-repeat;
  width: 300px;
  height: 264px;
  background-size: cover;

  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.exampleWithdrawal__chart_en{
  background-image: url("/landing/to-freelancers/chart_en.png") ;
}

@media screen and (max-width: 1200px) {
  .exampleWithdrawal__chart {
    width: 168px;
    height: 147.84px;
  }
}

.exampleWithdrawal__balance {
  position: absolute;
  top: 0;
  right: 0;

  background-image: url("/landing/to-freelancers/balance.png");
  background-position:  center center;
  background-repeat: no-repeat;
  width: 252px;
  height: 164px;
  background-size: cover;

  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.exampleWithdrawal__balance_en{
  background-image: url("/landing/to-freelancers/balance_en.png");
}

@media screen and (max-width: 1200px) {
  .exampleWithdrawal__balance {
    width: 141.12px;
    height: 91.84px;
  }
}
.exampleWithdrawal__replenishment {
  position: absolute;
  top: 29px;
  left: 0;

  background-image: url("/landing/to-freelancers/replenishment.png") ;
  background-repeat: no-repeat;
  background-position: center center;
  width: 208px;
  height: 40.07px;
  background-size: cover;

  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.exampleWithdrawal__replenishment_en{
  background-image: url("/landing/to-freelancers/replenishment_en.png") ;
}

@media screen and (max-width: 1200px) {
  .exampleWithdrawal__replenishment {
    width: 116.48px;
    height: 22.44px;
  }
}

.exampleWithdrawal__text {
  max-width: 400px;
  display: grid;
  grid-gap: 32px;
}
