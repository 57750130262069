.companyDescription__figure {
  position: absolute;
  content: "";
  margin: auto;
  top: 0px;
  left: -200px;
  background: url("/landing/to-customers/vector_gray.svg") center center
    no-repeat;
  width: 1013.36px;
  height: 790.78px;
  background-size: contain;
  opacity: 0.2;
  transform: rotate(90deg);
}
