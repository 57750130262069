.select{
  position: relative;
  width: 100%;
}

.select_background_white{
  background: white;
}

.select__field_noBorder{
  border-color: transparent;
  &:hover{
    border-color: transparent;
  }
}

.select__field{
  @extend %input;

  padding-right: 4px;
  overflow: hidden;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 32px;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  position: relative;
}

.select_mini_mini{
  height: 20px;
  border-radius: 5px;
}



.select__field_disabled{
  background-color: #F1F4F4;
}
.select__placeholder{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  @extend %paragraph;
  font-size: 13px;
  color: #737A92;
  padding-right: 20px;
}

@media screen and (max-width: 480px) {
  .select__placeholder{
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.select__field_opened{
  border: 1px solid #737A92;
}

.select__field_error{
  border: 1px solid #df2323 !important;
}

.select_kik_arrow{
  background: url("/icons/baseUI/arrow-icons/arrowDown.svg") center center
  no-repeat;
  width: 14px;
  height: 14px;
  transition: transform 0.5s;
  position: absolute;
  right: 15px;
  top: calc((48px - 14px) / 2);
}


@media screen and( max-width: 540px){
  .select__arrow{
    right: 10px;
  }
}
.select__arrow_to_top{
  transform: scaleY(-1);
}
.select__arrow_open_bot{
  transform: scaleY(1);
}
.select__arrow_open{
  transform: scaleY(-1);
}


.select__fieldSearchInput{
  border-radius: 0;
  border: none;
  appearance: none;
  width: 100%;
  height: 100%;
  padding-right: 40px;
  &::placeholder{
    color: #737A92;
  }
  &::-webkit-input-placeholder {
    color: #737a92;
  }
  &:focus{
    outline: none;
  }
}

.placeholder_mini_mini{
  font-size: 8px;
  &::-webkit-input-placeholder {
    color: #737a92;
    font-size: 8px;
  }
}
.select__list{
  z-index: 10;
  position: absolute;
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  background: #ffffff;
  max-height: 200px;
  overflow-y: scroll;
  scrollbar-width: none;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
  //opacity: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  padding: 7px;
  pointer-events: none;
  &.select__list_calendar{
    overflow: unset;
    max-height: unset;
    width: fit-content;
  }
  &.select__list_calendar_interval{
    display: grid;
    grid-gap: 20px;
    justify-items: center;
  }
}

.select__list::-webkit-scrollbar {
  //width: 0;
  width: 7px;
}
.select__listLoadingBlock{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 2px;
  overflow: hidden;
}

.select__listLoading {
  position: absolute;
  width: 30px;
  top: 0;
  left: 0;
  height: 100%;
  background: #3DE297;
  animation: shake 1s linear normal infinite;
}

@-webkit-keyframes shake {
  100% {
    left: calc(100%);
  }
}

.select__list::-webkit-scrollbar-thumb {
  background-color: #D7DBEB;
  border-radius: 5px;
}

.select__list_open{
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  visibility: visible;
  pointer-events: unset;
}

.select_notFound{
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @extend %paragraph;
  font-size: 14px;
  color: #737a92;
}
.select_notFoundText{
  width: 100%;
  text-align: center;
  line-height: 1.3;
  font-size: 13px;
}
.select__listContent{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: max-content;
  &.select__listContent_calendar{
    justify-content: center;
  }
}

.select__listContent_interval{
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;
}
.dateInput__name{
  @extend %paragraph;
  font-size: 14px;
  color: #282C38;
  height: 100%;
  display: flex;
  align-items: center;
}

.dateInput__calendar{
  @extend %paragraph;
  font-size: 12px;
  color: #282C38;
  height: 100%;
  display: flex;
  align-items: center;
}
.select__listItem{
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  //padding: 0px 15px;
  transition: 0.2s background-color;
  &:hover{
    background-color: #F1F4F4;
  }
}

.freelancerItem{
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 55px;
  align-items: center;
}
.freelancerItem__user{
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 10px;
}
.freelancerItem__userName, .freelancerItem__email, .freelancerItem__stateRole{
  @extend %paragraph;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 15px;
}

.freelancerItem__userName{
  font-weight: 500;
  font-size: 13px;
  color: #282C38;
}
.freelancerItem__email{
  font-weight: 400;
  font-size: 13px;
  color: #737A92;
}
.freelancerItem__state{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.freelancerItem__stateRole{
  font-weight: 400;
  font-size: 12px;
  color: #737A92;
}

.freelancerItem__invite{
  font-weight: 400;
  font-size: 12px;
  color: #3D6BE2;
  padding-right: 15px;
}

.freelancerItem__registered{
  font-weight: 400;
  font-size: 12px;
  color: #0eaf98;
  padding-right: 15px;
}

.freelancerItem__new{
  font-weight: 400;
  font-size: 12px;
  color: #5f33db;
  padding-right: 15px;
}

.freelancersList{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}
.freelancersList__item{
  background: #f1f4f4;
  height: 50px;
  border-radius: 8px;
  display: flex;
  padding: 0px 15px;
  max-width: 100%;
}
.freelancersList__itemInfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 42px);
}
.freelancersList__itemName, .freelancersList__itemEmail, .freelancersList__itemSRole{
  @extend %paragraph;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.freelancersList__itemName{
  color: #282C38;
  font-weight: 500;
  font-size: 13px;
}
.freelancersList__itemEmail{
  padding-top: 2px;
  font-size: 12px;
  color: #737A92;
}

.freelancersList__itemSRole {
  font-size: 10px;
}

.freelancersList__itemRemove{
  display: flex;
  padding-left: 30px;
  align-items: center;
  justify-content: center;
}
.freelancersList__itemRemoveIcon{
  background-image: url("/icons/account/company-account/createTask/deleteFile.svg");
  width: 17px;
  height: 17px;
  background-size: contain;
  transition: 0.2s opacity;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}
.select__category{
  padding-top: 10px;
  &:first-child{
    padding-top: 0px;
  }
}
.select_categoryName{
  padding-left: 10px;
  @extend %paragraph;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #282C38;
}

.workName__item{
  @extend %paragraph;
  font-size: 14px;
  color: #282C38;
  padding: 10px 0;
  line-height: 1.3;
}
.workName__itemSelected{
  line-height: 1;
  @extend %paragraph;
  font-size: 14px;
  color: #282C38;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: calc(100% - 70px);
}
.workName__itemSelectedText{
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

