.container {
  //overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  //background: #E5E5E5;

  transition: 0.5s;
}

.container__content {
  width: 100%;
  max-width: 1440px;
  padding: 0 130px;
}

.container_relative {
  position: relative;
}
.container_overflowHidden {
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .container__headerVoid {
    min-height: 85px;
  }
}

@media screen and (max-width: 1400px) {
  .container__content {
    padding: 0 80px;
  }
}

@media screen and (max-width: 1200px) {
  .container__content {
    padding: 0 40px;
  }
  .container__headerVoid {
    min-height: 66px;
  }
}

@media screen and (max-width: 540px) {
  .container__content {
    padding: 0 20px;
  }
}

@import "_background/banner";
@import "_background/advantages";

.container_exampleOfTheMainPage {
  flex-direction: column;
  background: linear-gradient(356.87deg, #ffffff 21.96%, #d7f0f6 110.46%);
}

.container_background_exampleOfTheMainPage {
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 60%;
    background: url("/backgrounds/banner.svg") no-repeat top center;
    background-size: cover;
  }

  &:after {
    position: absolute;
    z-index: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 60%;
    mix-blend-mode: multiply;
    background-image: url('/noice/noice.svg');
  }
}

.container_background_exampleOfTheMainPage_100 {
  &:before, &:after {
    height: 100%;
  }
}


.container_background_icebergToBottom {
  position: relative;
  overflow: hidden;
}

.container_background_footerLinks {
  background: #e3eff1;
}

.container_background_functionsAndRates {
  background: #f1f4f4;
  position: relative;
  &:before {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    content: "";
    border: 100px solid transparent;
    border-right: 100vw solid white;
    border-bottom: 68px solid white;
  }
}

.container_background_footerInfo {
  background: #282c38;
}

.container_background_earth {
  background: #00090a;
  position: relative;
  overflow: hidden;
  &:after {
    z-index: 1;
    position: absolute;
    content: "";
    width: 1463px;
    height: 1463px;
    left: -684px;
    top: -764px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #3de297 0%,
      rgba(61, 226, 151, 0) 100%
    );
    opacity: 0.6;
    filter: blur(154px);
  }
  &:before {
    z-index: 1;
    position: absolute;
    content: "";
    width: 1919px;
    height: 1919px;
    left: 299px;
    top: -232px;

    background: radial-gradient(
      50% 50% at 50% 50%,
      #0e0e3a 0%,
      rgba(11, 15, 58, 0) 100%
    );
    filter: blur(154px);
  }
}

.container__backgroundEarth {
  position: absolute;
  background-image: url("/landing/to-customers/earth.png");
  background-repeat: no-repeat;
  background-position: -200px center;
  background-size: cover;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 1640px;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .container__backgroundEarth {
    background-position: -300px center;
  }
}

.container_background_control {
  background: #f1f4f4;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 281px;
    left: 0;
    background: #ffffff;
  }
  &:after {
    position: absolute;
    content: "";
    margin: auto;
    top: 200px;
    bottom: 0;
    left: -300px;
    background: url("/landing/to-customers/vector_gray.svg") center center
      no-repeat;
    width: 900px;
    background-size: contain;
    height: 1000px;
    opacity: 0.2;
    transform: rotate(90deg);
  }
}

.container_background_cream {
  position: relative;
  background: #f1f4f4;
}

.container__content_noPadding {
  padding: 0 0;
}
