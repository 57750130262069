.instructionBlock__manual {
  height: 100%;
  background: #ffffff;
  border-radius: 30px;

  padding: 60px;

  display: grid;

  grid-template-rows: 74px max-content;

  grid-gap: 110px;
}

@media screen and (max-width: 1200px) {
  .instructionBlock__manual {
    grid-template-rows: 54px max-content;
    padding: 20px;
    grid-gap: 84px;

    max-height: 280px;
  }
}
