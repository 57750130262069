.table__head_task, .table__row_task{
  //grid-template-columns:
  //        40px
  //        minmax(80px, 2fr)
  //        minmax(120px, 4fr)
  //        4fr
  //        minmax(92px, 3fr)
  //        minmax(80px, 3fr)
  //        100px
  //        45px;
  grid-template-columns:
          40px
          minmax(80px, 2fr)
          //minmax(120px, 4fr)
          4fr
          //minmax(92px, 3fr)
          minmax(80px, 3fr)
          150px
          //45px;
}


@media screen and (max-width: 850px) {
  .table__head_task,  .table__row_task{
    grid-template-columns:
          40px
          minmax(80px, 3fr)
          //4fr
          minmax(80px, 2fr)
          100px
          45px;


   .table__headCell{
     &:first-child{
       display: none;
     }
   }
  }

}



.freelancerCell{
  display: flex;
  align-items: center;
}
.freelancerCell__avatar{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f6f6f6;
}
.freelancerCell__textData{
  display: grid;
}
.freelancerCell__name{
  @extend %paragraph;
  font-size: 14px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.freelancerCell__type{
  @extend %paragraph;
  display: none;
}
@media screen and (max-width: 760px) {
  .freelancerCell__type{

    display: block;
    padding-left: 14px;
    font-size: 12px;
    line-height: 130%;

    color: #737A92;
  }
}

.table__head_freelancer, .table__row_freelancer{
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 760px) {
  .table__row_freelancer{
    grid-template-columns: unset;
    grid-auto-rows: max-content;
    .table__cell{
      &:last-child{
        display: none;
      }
    }
    background: #FFFFFF;
    /* Main mobile */

    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 12px;

  }
}

.table__head_default{
  height: 48px;
}
.taskFreelancers{
  display: flex;
}
.taskFreelancers__avatar{
  width: 35px;
  height: 35px;
  background-color: #f6f6f6;
  border-radius: 50%;
  margin-left: -22px;
  box-shadow: -2px 0px 3px rgba(28, 29, 31, 0.33);
  overflow: hidden;
  &:first-child{
    margin-left: 0;
    box-shadow: none;
  }
}
.taskFreelancers__fields{

}
.taskFreelancers__name{

}

.taskFreelancers__item{
  display: grid;
  grid-template-columns: max-content max-content;
}
.taskFreelancers__image{
  width: 100%;
  height: 100%;
}

.taskStatus {
  cursor: pointer;
  @extend %paragraph;
  border-radius: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
}

.taskStatus_new{
  background-color: #F0E6F7;
  color: #8A3AC8;
}
.taskStatus_inProcess{
  background-color: #FFE6D8;
  color: #F2B518;
}

.taskStatus_reviewing{
  background-color: #FFE6A5;
  color: #F2B518;
}

.taskStatus_assigned{
  background-color: #e5ddf8;
  color: #593ac8;
}
.taskStatus_paid{
  background-color: #E6F7EC;
  color: #30D42C;
}

.taskStatus_canceled{
  background-color: #F7E8E6;
  color: #C8483A;
}


.taskPrice{
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: right;
}
.taskPrice__price{
  @extend %paragraph;
  font-size: 13px;
}
.taskPrice__fullPrice
{
  @extend %paragraph;
  font-size: 11px;
  color: #737A92;
}