.instructionBlock__title {
  font-size: 32px;
  max-width: 305px;
  line-height: 110%;
}

@media screen and (max-width: 1200px) {
  .instructionBlock__title {
    font-size: 20px;
  }
}
