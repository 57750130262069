.example__safetyGlasses {
  z-index: 1;
  display: grid;
  //grid-template-columns: 300px 300px;
  grid-template-columns: 300px 100%;
  grid-auto-rows: max-content;
  grid-gap: 100px 0;
  grid-area: 1/3/4/5;
}

@media screen and (max-width: 1300px) {
  .example__safetyGlasses {
    grid-area: 1/3/4/4;
  }
}
@media screen and (max-width: 1200px) {
  .example__safetyGlasses {
    grid-area: 2/1/7/1;
    grid-gap: 50px 0;
  }
}

@media screen and (max-width: 600px) {
  .example__safetyGlasses {
    grid-template-columns: 300px 1fr;
  }
}

@media screen and (max-width: 540px) {
  .example__safetyGlasses {
    grid-template-columns: 280px;
  }
}
