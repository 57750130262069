.exampleBlock {
  overflow: hidden;
  position: relative;

  padding: 84px 110px;

  background-color: #ffffff;
  box-shadow: 0px 64px 174px -50px rgba(0, 0, 0, 0.2);
  border-radius: 30px;

  display: flex;
  align-content: center;

  width: 100%;
  &:before {
    z-index: 0;
    position: absolute;
    content: "";
    top: 0;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.exampleBlock_left {
  justify-content: flex-end;
  &:before {
    left: 0;
  }
}

.exampleBlock_left_one {
  &:before {
    width: 758px;
    background-image: url("/landing/main/compoentns/exampleBlock/left_one.svg");
  }
}

.exampleBlock_left_two {
  &:before {
    width: 778px;
    background-image: url("/landing/main/compoentns/exampleBlock/left_two.svg");
  }
}

.exampleBlock_left_three {
  &:before {
    width: 858px;
    background-image: url("/landing/main/compoentns/exampleBlock/left_three.svg");
  }
}
.exampleBlock_right {
  justify-content: flex-start;
  &:before {
    right: 0;
    background-position: right;
  }
}

.exampleBlock_right_one {
  &:before {
    width: 1467.46px;
    background-image: url("/landing/main/compoentns/exampleBlock/right_one.svg");
  }
}

.exampleBlock_right_two {
  &:before {
    width: 1467.46px;
    background-image: url("/landing/main/compoentns/exampleBlock/right_two.svg");
  }
}

@media screen and (max-width: 1300px) {
  .exampleBlock {
    padding: 84px 56px;
  }
}

@media screen and (max-width: 1200px) {
  .exampleBlock {
    padding: 20px 20px 20px 20px;

    //flex-direction: column;
    //align-items: center;
    //justify-content: space-between;
    display: grid;
    grid-template-rows: max-content max-content;
    grid-gap: 39px;
    justify-content: center;
    &:before {
      visibility: hidden;
    }
  }
}

@media screen and (max-width: 800px) {
  .exampleBlock {
    grid-gap: 24px;
  }
}

.exampleBlock__text {
  max-width: 400px;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 32px;
}
@media screen and (max-width: 800px) {
  .exampleBlock__text {
    grid-gap: 24px;
  }
}

.exampleBlock__title {
  font-size: 34px;
}

@media screen and (max-width: 1200px) {
  .exampleBlock__title {
    font-size: 20px;
  }
}

.example__image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.example__image_five {
  background-image: url("/landing/to-customers/examples/fifth.png");
  background-size: contain;
  width: 570px;
  height: 579px;
  max-width: 50%;
  left: 0;
  top: 0;
}

.example__image_five_en{
  background-image: url("/landing/to-customers/examples/fifth_en.png");
}

@media screen and(max-width: 1200px) {
  .example__image_five {
    order: -1;
    position: relative;
    left: unset;
    width: 100%;
    height: 271px;
    max-width: unset;
  }
}

.example__image_four {
  background-image: url("/landing/to-customers/examples/fourth_ru.png");
  width: 410px;
  height: 337.87px;
  right: 110px;
  background-size: contain;
}

.example__image_four_en{
  background-image: url("/landing/to-customers/examples/fourth_en.png");
}

@media screen and(max-width: 1300px) {
  .example__image_four {
    right: 56px;
  }
}

@media screen and(max-width: 1200px) {
  .example__image_four {
    order: -1;
    position: relative;
    right: unset;
    margin-top: 20px;
    width: 100%;
    height: 197.78px;
  }
}

.example__image_three {
  background-image: url("/landing/to-customers/examples/threed.png");
  background-size: contain;
  width: 595px;
  height: 525px;
  max-width: 50%;
  left: 0;
  top: -10px;
}

.example__image_three_en {
  background-image: url("/landing/to-customers/examples/threed_en.png");
}

@media screen and(max-width: 1200px) {
  .example__image_three {
    order: -1;
    top: 0;
    position: relative;
    width: 100%;
    height: 247px;
    max-width: unset;
  }
}

.example__image_two {
  background-image: url("/landing/to-customers/examples/second.png");
  width: 556px;
  height: 100%;
  max-width: 50%;
  right: 0;
  top: 0;
}

.example__image_two_en {
  background-image: url("/landing/to-customers/examples/second_en.png");
  background-size:contain ;
}

@media screen and(max-width: 1200px) {
  .example__image_two {
    order: -1;
    position: relative;
    width: 100%;
    height: 278px;
    max-width: unset;
  }
}

.example__image_one {
  order: -1;
  position: relative;
  background-image: url("/landing/to-customers/examples/first_ru.png");
  background-size: contain;
  max-width: 575px;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1;
}

.example__image_one_en{
  background-image: url("/landing/to-customers/examples/first_en.png");
  max-width: 675px;
}

@media screen and(min-width: 1200px) {
  .example__image_one {
    left: -50px;
  }
}

@media screen and(max-width: 1200px) {
  .example__image_one {
    background-size: contain;
    order: -1;
    position: relative;
    width: 100%;
    height: 218px;
  }
}
