@import "fonts";
@import "fonts/golos/golostext.css";
//
//
//@Import "Golos-Text/Golos-Text_Regular.css";
//@Import "Golos-Text/Golos-Text_Bold.css";
* {
  //transition: .5s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@import "landing/components/footerLinks/footerLinks";

@import "components/paragraph/paragraph";
@import "components/input/input";
@import "components/title/title";
@import "components/button/button";
@import "components/void/void";
@import "components/checkMark/checkMark";
@import "components/modalWindow/modalWindow";

@import "landing/components/container/container";
@import "landing/components/exampleBlock/exampleBlock";

@import "landing/components/modal/modal";

@import "landing/index/banner/banner";
@import "landing/index/advantages/advantages";
@import "landing/components/ambitiousCompanies/ambitiousCompanies";
@import "landing/index/experience/experience";
@import "landing/index/optimization/optimization";
@import "landing/index/accordance/accordance";
@import "landing/components/appealForm/appealForm";
@import "landing/components/FAQ/FAQ";
@import "landing/components/signUpRequest/signUpRequest";
@import "landing/index/qualities/qualities";
@import "landing/authorization/authorization";

@import "landing/functionsAndRates/functionsAndRates/functionsAndRates";
@import "landing/functionsAndRates/tariffPackage/tariffPackage";

@import "landing/components/iceberg/iceberg";
@import "landing/components/verticalLines/verticalLines";

@import "account/account";

@import "landing/toCustomers/toCustomers";

@import "landing/toFreelacners/toFreelancers";

@import "landing/about/about";
@import "landing/faq/faq";

@import "landing/contacts/contacts";

@import "landing/instruction/instruction";

@import "landing/blog/blog";

@import "error/error";
@import "components/modal/modal";
@import "components/select/select";
@import "components/formError/formError";
@import "components/table/table";
@import "components/table/taskTable";
@import "components/table/financeTable";
@import "components/table/paymentsTable";

.reactModal {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;

  overflow: scroll;
}




