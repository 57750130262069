.header__splitter {
  height: 100%;
  width: 1px;
  &:before {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    content: "";
    height: 20px;
    width: 1px;
    opacity: 0.5;
    background: #ffffff;
  }
}

.header__splitter_black {
  &:before {
    background: black;
  }
}

@media screen and (max-width: 1200px) {
  .header__splitter {
    display: none;
  }
}
