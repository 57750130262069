.example__textParagraph {
  max-width: 336px;
  font-size: 24px;
  grid-area: 3/1/3/3;
  align-self: end;
  padding-left: 20px;
}

@media screen and (max-width: 1200px) {
  .example__textParagraph {
    font-size: 18px;
    grid-area: 8/1/8/1;
  }
}

@media screen and (max-width: 540px) {
  .example__textParagraph {
    grid-area: 3;
    max-width: 280px;
  }
}
