.error {

}

.error__content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serverError{
  border-radius: 14px;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.serverError_white {
  background: white;
}

.serverError__content{
  display: flex;
  width: 100%;
  flex-direction: column;
}

.serverError__iconWrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}
.serverError__icon{
  width: 140px;
  height: 140px;
}
.serverError__textContentWrapper{
  display: flex;
  width: 100%;
  justify-content: center;
}
.serverError__textContent{
  display: flex;
  max-width: 400px;
  flex-direction: column;
  text-align: center;
}
.serverError__title{
  @extend %paragraph;
  font-weight: 500;
  margin: 0;
  padding-top: 20px;
  font-size: 22px;
}
.serverError__description{
  @extend %paragraph;
  margin: 0;
  padding-top: 15px;
  font-size: 14px;
}
.serverError__reloadWrapper{
  display: flex;
  justify-content: center;
  padding-top: 35px;
}

.preloader{
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 12;
  justify-content: center;
  align-items: center;
  background: white;
}

.mainPreloader{
  transition: .5s;
  visibility: hidden;
  opacity: 0;

  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 12;
  justify-content: center;
  align-items: center;
  background: white;
}

.preloader_show{
  visibility: visible;
  opacity: 1;
}


.spinner{
  width: 80px;
  height: 80px;

  border: 2px solid #f3f3f3;
  border-top:3px solid #3de297;
  border-radius: 100%;

  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  margin: auto;

  animation: spin 1s infinite linear;
}

@keyframes spin {
  from{
    transform: rotate(0deg);
  }to{
     transform: rotate(360deg);
   }
}

.miniPreloader{
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.preloader__loadingIcon{
  width: 65px;
  height: 65px;
}

.preloader__loadingIcon_mini{
  width: 40px;
  height: 40px;
}