.tableWrapper{
  width: 100%;
  min-height: 400px;
  position: relative;
  padding-bottom: 50px;
}

.table__row{
  display: grid;
  align-items: center;
  position: relative;
  height: max-content;
}

.table__m{
  margin: 10px 0 ;
}
@media screen and (max-width: 540px) {
  .table__row_without_left_padding{
    padding: 0;
  }
}

.table__companyBlock{
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
@media screen and (max-width: 540px) {
  .table__companyBlock{
    flex-direction: column;
  }
}

.table__companyInviteDate{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}
.table__companyContainer{
  display: grid;
  grid-template-columns: 42px 1fr;
  grid-template-rows: max-content;
  transition: .5s;
  grid-gap: 16px;
}

@media screen and (max-width: 540px) {
  .table__companyContainer{
    grid-gap: 8px;
  }
}

.table__companyAvatar{
  height: 42px;
  width: 42px;

  background: #724DDB;
  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  @extend %paragraph;
  font-size: 12px;
  color: white;
}
.table__companyTextData{
  display: grid;
  overflow-x: hidden;
}
.table__companyName{
  @extend %paragraph;
  font-size: 16px;
  line-height: 130%;
  max-width: 250px;
  word-wrap: break-word;
}

@media screen and (max-width: 540px) {
  .table__companyName{
    font-size: 14px;
  }
}

.table__companyEmail{
  @extend %paragraph;
  font-size: 13px;
  line-height: 130%;
  color: #3D6BE2;
  word-wrap: break-word;
}

.table__head{
  width: 100%;
  background-color: #F8F9FD;
  border-radius: 10px;
}
@media screen and (max-width: 760px) {
  .table__head{
    display: none;
  }
}

.table__headCell{
  @extend %paragraph;
  color: #737A92;
  font-weight: 400;
  font-size: 14px;
  padding-left: 10px;
  //&:first-child{
  //  padding-left: 0;
  //}
}

.table__row_default{
  height: 58px;
  border-bottom: 1px solid #D7DBEB;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
}

@media screen and (max-width: 760px) {
  .table__row_default {
    height: max-content;
  }
}

.table__cell{
  @extend %paragraph;
  color: #282C38;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  //overflow: hidden;
  padding-left: 10px;
}

.table__cell_wrap{
  white-space: unset;
}

@media screen and (min-width: 760px) {
  .table__cell_end{
    display: flex;
    justify-content: flex-end;
  }
}

.PaymentMethod__image{
  width: 20px;
  height: 20px;
}
.PaymentMethod__name{
  font-size: 14px;
  padding-left: 5px;
}

.table__actionCell{
  display: none;
}
.table__cellName{
  display: none;
}

.table__cellText{
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.table__cellText_green{
  color: #2AB408;
}

.table__cellText_red{
  color: #DF2323;
}

@media screen and (max-width: 760px) {
  .table__cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
  }
  .table__cell_mobile_hide{
    display: none;
  }
  .table__actionCell{
    //transform: rotate(90deg);
    display: block;
  }
  .table__cellName{
    display: block;
    font-family: "Golos-Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #737A92;
  }
  //.table__cellText{
  //  padding-left: 50px;
  //}
}
.rowAction{
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}
.rowAction__button{
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.2s background-color;
  cursor: pointer;
  &:hover{
    background-color: #F1F4F4;
  }
}


.rowAction__Icon{
  display: flex;
  content: '';
  background-image: url("/icons/account/company-account/edit.svg");
  background-size: cover;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
}




.table__freelTaskRowButtons {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px;
}

.table__freelTaskRowFirstDataUnderInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@media screen and (max-width: 540px){
  .table__freelTaskRowFirstDataUnderInfo{
    grid-template-columns: unset;
    grid-template-rows: auto auto;
    grid-gap: 5px;
  }
}
//.table__freelTaskStatus, .table__freelancerTaskRole {
//  position: relative;
//  @extend %paragraph;
//  display: flex;
//  align-items: center;
//  gap: 5px;
//  font-weight: 400;
//  font-size: 12px;
//  line-height: 130%;
//  text-align: center;
//  color: #DF2323;
//  justify-self: center;
//}
//
//@media screen and (max-width: 800px) {
//  .table__freelTaskStatus, .table__freelancerTaskRole {
//    justify-self: end;
//    align-self: end;
//  }
//}
//
//@media screen and (max-width: 450px) {
//  .table__freelTaskStatus, .table__freelancerTaskRole {
//    justify-self: self-start;
//    align-self: end;
//  }
//}


.table__freelancerTaskRole{
  justify-content: flex-end;
}

@media screen and (max-width: 800px) {
  .table__freelancerTaskRole{
    grid-area: 2/2/2/2;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 450px) {
  .table__freelancerTaskRole{
    grid-area: unset;
    justify-content: flex-start;
  }
}

.table__freelTaskClock{
  background-image: url("/icons/account/company-account/icons/clock.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;

}

.table__freelTaskRowPriceData{
  display: grid;
  grid-gap: 8px;
  grid-template-rows: max-content;
  align-content: center;
}

@media screen and (max-width: 800px) {
  .table__freelTaskRowPriceData {
    grid-area: 2/1/3/1;
    align-content: start;
  }
}

