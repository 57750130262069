.blog__underTitleOfArticle{
  @extend %paragraph;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #282C38;
}


.blog__underUnderTitleOfArticle{
  @extend %paragraph;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #282C38;
}