.accordance {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .accordance {
    height: 624px;
  }
}

@media screen and (max-width: 540px) {
  .accordance {
    padding-top: 100px;
    justify-content: space-between;
    height: 725px;
    flex-direction: column;
  }
}
@import "__description/accordance__description";
@import "__img/accordance__img";
@import "__relevance/accordance__relevance";
