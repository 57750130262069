.advantages__container {
  display: flex;
  align-items: center;
}

.advantages__content {
  //position: absolute;
  //height: 100%;
  //width: 100vw;
  //max-width: 1200px;
  //margin: auto;
  //left: 20px;
  //right: 0;
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  //&:last-child{
  //  border-right:1px dashed rgba(0, 0, 0, 0.4);
  //}
  bottom: 0;
  height: 100%;
  max-height: 600px;
  width: 100vw;
  max-width: 1200px;
  display: grid;

  grid-template-columns: repeat(auto-fit, 300px);
  align-content: center;
  grid-auto-rows: max-content;
}

@media screen and (max-width: 1300px) {
  .advantages__content {
    width: 100%;
    max-width: unset;
    align-content: end;
  }
}

@media screen and (max-width: 1200px) {
  .advantages__content {
    align-content: end;
    grid-template-columns: repeat(auto-fit, 300px);
    padding-bottom: 50px;
    grid-row-gap: 64px;
    max-height: unset;
  }
}

@media screen and (max-width: 710px) {
  .advantages__content {
    grid-template-columns: repeat(auto-fit, 250px);
    align-content: end;
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 540px) {
  .advantages__content {
    align-content: end;
    width: 100%;
    max-width: unset;
    grid-template-columns: repeat(auto-fit, 1fr);

    padding-bottom: 50px;
    border-right: 1px dashed rgba(0, 0, 0, 0.4);
  }
}
