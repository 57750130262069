.instructionBanner__content {
  position: relative;
  z-index: 1;
  padding-top: 124px;
  padding-bottom: 63px;

  display: grid;
  grid-template-rows: max-content 57px;
  //justify-items: center;
  grid-gap: 130px;
}

@media screen and (max-width: 1200px) {
  .instructionBanner__content {
    padding-top: 80px;
    padding-bottom: 32px;
    grid-template-rows: max-content 49px;

    grid-gap: 80px;
  }
}

.instructionBanner__description {
  display: grid;
  grid-template-rows: 34px max-content;
  //justify-items: center;
  grid-gap: 32px;
}

.instructionBanner__pill {
  width: max-content;

  @extend %paragraph;

  color: #33db94;

  padding: 8px 16px;
  //font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  background: rgba(51, 219, 148, 0.2);
  border-radius: 30px;

  justify-self: start;
}

@media screen and (max-width: 1200px) {
  .instructionBanner__pill {
    font-size: 12px;
  }
}

.instructionBanner__buttons {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-gap: 16px;
}

@media screen and (max-width: 1200px) {
  .instructionBanner__buttons {
    grid-template-columns: minmax(136px, 200px) minmax(136px, 200px);
  }
}

.instructionBanner__button {
  font-size: 18px;
}

@media screen and (max-width: 1200px) {
  .instructionBanner__button {
    font-size: 12px;
  }
}

.instructionBanner__titleText {
  text-align: left;
  font-size: 35px;
  width: 100%;
  max-width: 870px;
}
