.appealForm__textForm {
  height: 100%;
  width: 100%;
  max-width: 400px;
  max-height: 158px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 800px) {
    text-align: center;
  }
}
