.appealForm__description {
  width: 100%;
  height: 100%;
  max-width: 504px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

@media screen and (max-width: 800px) {
  .appealForm__description {
    align-items: center;
    max-width: 100%;
  }
}
