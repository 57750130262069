.header__auth {
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  //>:first-child{
  //  margin-right: 24px;
  //}
}

@media screen and (max-width: 1200px) {
  .header__auth {
  }
}

@media screen and (max-width: 540px) {
  .header__auth {
    grid-gap: 8px;
  }
}
