.exampleFreelancersPage {
  height: 768px;
  //position: relative;
}

@media screen and(max-width: 540px) {
  .exampleFreelancersPage {
    height: 450px;
    //position: relative;
  }
}

@import "__image/exampleFreelancersPage__image";
@import "__page/exampleFreelancersPage__page";
