.instructionBlock__manuals {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 1200px) {
  .instructionBlock__manuals {
    grid-template-columns: 1fr;
    grid-template-rows: 280px 280px;

    grid-gap: 20px;
  }
}
