.blog__newsDetails {
  margin-top: 40px;

  padding: 14px;
  @extend %paragraph;
  font-size: 14px;
  line-height: 140%;
  color: #282c38;

  display: grid;
  grid-template-columns: max-content 18px;
  grid-gap: 10px;

  &:after {
    position: relative;
    content: "";
    background-image: url("/icons/baseUI/arrow-icons/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: center center;
    transform: rotate(-90deg);
    height: 100%;
  }
}

@media screen and (max-width: 700px) {
  .blog__newsDetails {
    display: none;
  }
}
