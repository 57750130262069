.functionsAndRates__savingTime {
  background-color: #ffffff;
  box-shadow: 0px 64px 174px -50px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  display: flex;
  justify-content: space-between;
}

.functionsAndRates__savingTime {
  z-index: 1;
  position: relative;
  overflow: hidden;

  height: max-content;
  align-items: center;
  padding: 100px 110px 110px 110px;

  &:before {
    z-index: 0;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 560px;
    background-image: url("/landing/functionsAndRates/blockForSavingTime.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.functionsAndRates__exampleOfChat {
  position: relative;
  background-image: url("/landing/functionsAndRates/exampleOfChat.png");
  background-repeat:   no-repeat;
  background-position:  center center;
  width: 446px;
  height: 380px;
  background-size: cover;
}

.functionsAndRates__exampleOfChat_en{
  background-image: url("/landing/functionsAndRates/exampleOfChat_en.png");
}

@media screen and(max-width: 1200px) {
  .functionsAndRates__savingTime {
    padding: 45px 20px;

    display: grid;

    grid-gap: 59px;

    align-items: center;
    justify-items: center;
    justify-content: center;

    grid-template-columns: max-content max-content;
  }

  .functionsAndRates__exampleOfChat {
    width: 235.69px;
    height: 201px;
  }
}

@media screen and(max-width: 680px) {
  .functionsAndRates__savingTime {
    padding: 45px 20px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: unset;
    grid-template-rows: max-content max-content;
    grid-gap: 59px;
    justify-content: center;
  }

  .functionsAndRates__exampleOfChat {
    width: 235.69px;
    height: 201px;
  }
}
