.secureTransaction {
  padding-top: 160px;
  padding-bottom: 77px;
  height: max-content;
  display: flex;
  align-items: center;
}

.secureTransaction__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
}

.secureTransaction__text {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.secureTransaction__title {
  max-width: 370px;
}

.secureTransaction__nuance {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto;
  grid-gap: 16px;

  padding: 8px 16px;

  background: #e7edee;
  border-radius: 10px;
  &:before {
    position: relative;
    content: "";
    background: url("/landing/to-customers/gift.svg") center center no-repeat;
    height: 24px;
    width: 24px;
    background-size: cover;
  }
}

.secureTransaction__shild {
  background: url("/landing/to-customers/shild.png") center center no-repeat;
  width: 422px;
  height: 400px;
  background-size: cover;
}

@media screen and (max-width: 1200px) {
  .secureTransaction {
    padding-top: 82px;
    padding-bottom: 82px;
  }
  .secureTransaction__title {
    max-width: 221px;
  }
  .secureTransaction__content {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-gap: 48px;
    justify-items: center;
    justify-content: center;
  }
  .secureTransaction__shild {
    width: 280px;
    height: 265.4px;
    grid-area: 1;
  }
}
