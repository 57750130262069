.optimization__relevance {
  margin-bottom: 10px;
  padding: 8px 16px;
  width: max-content;
  height: 29px;
  background: rgba(72, 152, 225, 0.2);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
