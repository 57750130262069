.button_theme_banner {
  width: 234px;
  height: 56px;
  justify-content: space-between;
  padding: 1px 6px;
  transition: 0.5s;
}
@media screen and (max-width: 1200px) {
  .button_theme_banner {
    width: 221px;
    height: 56px;
  }
}

@media screen and (max-width: 540px) {
  .button_theme_banner {
    width: 100%;
    height: 56px;
  }
}
