.experience {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .experience {
    height: 724px;
  }
}

@media screen and (max-width: 540px) {
  .experience {
    height: 650px;
    padding-top: 30px;
    flex-direction: column;
    justify-content: space-between;
  }
}

@import "__description/experience__description";
@import "__img/experience__img";
@import "__relevance/experience__relevance";
