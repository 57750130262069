.banner__buttonText {
  font-size: 18px;
  line-height: 140%;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .banner__buttonText {
    font-size: 16px;
  }
}

@media screen and (max-width: 540px) {
  .banner__buttonText {
    font-size: 14px;
  }
}
