.optimization__imgVoid {
  width: 600px;
}
.optimization__img {
  position: absolute;
  background: url("/landing/optimization/pig.png") right bottom no-repeat;
  width: 800px;
  height: 497px;
  background-size: contain;
  right: 0px;
}
@media screen and (max-width: 900px) {
  .optimization__imgVoid {
    width: 300px;
  }
  .optimization__img {
    background: url("/landing/optimization/pig.png") right bottom no-repeat;
    width: 499px;
    height: 280px;
    background-size: contain;
    right: -50px;
  }
}

@media screen and (max-width: 540px) {
  .optimization__imgVoid {
    display: none;
  }
  .optimization__img {
    position: relative;
    background: url("/landing/optimization/pig.png") right bottom no-repeat;
    width: 499px;
    height: 280px;
    background-size: contain;
    right: 80px;
  }
}
