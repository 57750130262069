.header {
  z-index: 7;
  height: 85px;
  width: 100%;
  top: 0;
  transition: 0.5s top;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .header {
    height: 66px;
  }
}

@media screen and (max-width: 540px) {
  .header {
    height: 65px;
  }
}

.header__switchLang {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 2px;
  width: 100%;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff;
  top: calc(100%);
  opacity: 0;
  visibility: hidden;
  transition: .5s;
}
.header__switchLang_open {
  top: calc(100% + 8px);
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 540px) {
  .header__switchLang{
    padding: 15px;
  }
}

.header__langEl {
  height: 50%;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 12px;
}

.header__langImg{
  height: 18px;
  width: 22px;
  object-fit: cover;
  border-radius: 5px;
}
@media screen and (max-width: 1200px) {
  .header__langEl {
    justify-content: center;
    padding: 0;
  }
}

.header__lang_hide {
  display: none;
}

.header__langEl:hover {
  background: #f1f4f4;
}

.header__container {
  width: 100%;
  max-width: 1180px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__linksPopupContent {
  position: relative;
}
.header__linkPopup {
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content 14px;
  grid-gap: 8px;
  align-items: center;
  justify-content: center;
  &:after {
    position: relative;
    content: "";
    top: 2px;
    background-image: url("/icons/baseUI/arrow-icons/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: center center;
    height: 14px;
    width: 14px;
    background-size: contain;
  }
}

.header__linkPopup_white {
  &:after {
    background-image: url("/landing/arrowDown_white.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.header__popupOfLink {
  z-index: 10;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  width: max-content;

  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: grid;
  grid-auto-rows: 34px;
  grid-template-columns: 230px;
  align-items: center;
  margin-bottom: 0;
  transition: 0.5s;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  height: max-content;
  max-height: 0;
  padding: 0 8px 0 24px;
}

.header__popupOfLink_show {
  display: grid;
  margin-bottom: 24px;
  max-height: 300px;
  padding: 8px 18px;
}

@media screen and (max-width: 1200px) {
  .header__popupOfLink {
    position: relative;
    top: 0;
    //

    width: 100%;
    background: #f4f7f7;
    box-shadow: none;
    margin-bottom: 0;
  }
  .header__popupOfLink_show {
    margin-bottom: 24px;
  }
}

@import "__logo/header__logo";
@import "__menu/header__menu";
@import "__links/header__links";
@import "__splitter/header__splitter";
@import "__auth/header__auth";
@import "__languageSwitch/header__languageSwitch";
@import "__signIn/header__signIn";
@import "__arrow/header__arrow";
@import "__flag/header__flag";
@import "__language/header__language";
@import "__buttonMenu/header__buttonMenu";
@import "__fix/header__fix";
@import "__paragraphMenu/header__paragraphMenu";
@import "__paragraphLinks/header__paragraphLinks";
