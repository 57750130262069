.qualities {
  position: relative;
  background: linear-gradient(353.58deg, #ffffff -44.6%, #eaf6f9 116.56%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 540px) {
  .qualities {
    height: 2112px;
    justify-content: flex-start;
  }
}

.qualities__description {
  font-family: Golos Text, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.qualities__description_experience {
  color: #e17a48;
}
.qualities__description_optimization {
  color: #4898e1;
}
.qualities__description_accordance {
  color: #5ccf69;
}
@import "__whiteTriangle/qualities__whiteTriangle";
