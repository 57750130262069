.exampleFreelancersPage__page {
  position: relative;
  z-index: 1;
  margin-top: 100px;
  display: grid;
  grid-template-rows: max-content max-content;
  justify-items: center;
  grid-gap: 58px;
  height: 612px;
  &:after {
    position: absolute;
    content: "";
    margin-right: 50vw;
    bottom: 0;
    border: 150vw solid transparent;
    border-right: 0 solid white;
    border-bottom: 96px solid white;
  }
}

@media screen and (max-width: 540px) {
  .exampleFreelancersPage__page {
    height: 450px;
    &:after {
      display: none;
    }
  }
}
