.companyDescription {
  position: relative;
  //height: 1371px;
  height: max-content;
  padding-bottom: 120px;

  &:after {
    position: absolute;
    content: "";
    margin: auto;
    bottom: 0px;
    left: 400px;
    background: url("/landing/to-customers/vector_gray.svg") center center
      no-repeat;
    width: 3494.39px;
    height: 2725.25px;
    background-size: contain;
    opacity: 0.2;
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 540px) {
  .companyDescription {
    height: 1200px;
  }
}
@import "__figure/companyDescription__figure";
@import "__content/companyDescription__content";
@import "__aboutCompany/companyDescription__aboutCompany";
@import "__pill/companyDescription__pill";
@import "__howWast/companyDescription__howWast";
@import "__title/companyDescription__title";
@import "__mainTitle/companyDescription__mainTitle";
@import "__paragraph/companyDescription__paragraph";
@import "__bottomBlocks/companyDescription__bottomBlocks";
@import "__our/companyDescription__our";
@import "__button/companyDescription__button";
