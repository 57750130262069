.functionsAndRates__infoBlocks {
  display: grid;
  justify-content: center;
  grid-gap: 120px;
}

//@media screen and(max-width: 1200px) {
//  .functionsAndRates__infoBlocks {
//    display: grid;
//    grid-template-rows: 600px 524px;
//    grid-gap: 60px;
//  }
//}
