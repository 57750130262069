.advantages {
  min-height: 600px;
  width: 100%;
  //min-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.advantages__whiteTriangle {
  z-index: 1;
  position: absolute;
  bottom: 0;
  border: 120px solid transparent;
  border-right: 100vw solid white;
  border-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .advantages__whiteTriangle {
    border: 60px solid transparent;
    border-right: 100vw solid white;
    border-bottom: 0;
  }
  .advantages {
    min-height: 640px;
  }
}

@media screen and (max-width: 540px) {
  .advantages {
    min-height: 916px;
    justify-content: flex-start;
  }
}

@import "__advantage/advantages__advantage";
@import "__advantageContainer/advantages__advantageContainer";
@import "__paragraph/advantages__paragraph";
@import "__image/advantages__image";
@import "__content/advantages__content";
