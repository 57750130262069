.banner {
  position: relative;
  min-height: 700px;
  max-height: 900px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:before {
    z-index: -1;
    position: absolute;
    right: -800px;
    bottom: -200px;
    content: "";
    background: url("/landing/main/banner/banner_backgroundGroup.svg") center
      center no-repeat;
    width: 120%;
    height: 120%;
  }
}

.banner__content {
  height: 100%;
  display: flex;
  align-items: center;
}

.banner__personalAccount {
  position: relative;
  width: 50%;
  max-width: 1280px;
  height: 720px;
}

.banner__personalAccountImg {
  background-image: url("/landing/to-customers/mainScreen_ru.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1180px;
  height: 720px;
  position: absolute;
  left: 0;
  top: 50px;
}


.banner__personalAccountImg_en{
  background-image: url("/landing/to-customers/mainScreen_en.png");
}

.banner__personalAccountImg_uz{
  background-image: url("/landing/to-customers/mainScreen_uz.png");
}
@media screen and (max-width: 1200px) {
  .banner__content {
    align-items: flex-start;
  }
  .banner__personalAccountImg {
    left: 0px;
  }
}
@media screen and (max-width: 540px) {
  .banner {
    min-height: 784px;
    max-height: 1096px;
    height: 100vh;
  }
  .banner__content {
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .banner__personalAccount {
    width: 100%;
  }

  .banner__personalAccountImg {
    background-position: left center;
  }
}

@import "__description/banner__description";
@import "__paragraphDescription/banner__paragraphDescription";
@import "__buttonText/banner__buttonText";
