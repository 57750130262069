.header__languageSwitch {
  position: relative;
  width: 90px;
  height: 48px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header__languageSwitch_black {
  border-color: #d7d8de;
}
.header__language_hover:hover {
  border: 1px solid #282c38;
}

.header__languageSwitch_focus {
  border: 1px solid #3de297;
}

@media screen and (max-width: 1200px) {
  .header__languageSwitch {
    width: 74px;
    height: 34px;
  }
}

@media screen and (max-width: 540px) {
  .header__languageSwitch {
    width: 34px;
    height: 34px;
  }
}
