.footerLinks {
  height: 383px;
  position: relative;
}

.footerLinks__content {
  position: absolute;
  //
  //margin: auto;
  //left: 0;
  //right: 0;
  height: 100%;

  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
}

@media screen and (max-width: 1200px) {
  .footerLinks__content {
    grid-template-columns: repeat(3, minmax(250px, 300px));
    //grid-template-columns: repeat(auto-fit, max-content);
    //grid-template-columns: repeat(auto-fit, auto);
  }
}

.footerLinks_paragraph {
  font-size: 14px;
}

.footerLinks__el {
  height: 100%;
  width: 100%;
  max-width: 300px;
  border-left: 1px dashed rgba(0, 0, 0, 0.4);

  display: grid;
  grid-auto-rows: 190px;
}

.footerLinks__el_first {
  display: unset;
}

.footerLinks__el_rightBorder {
  border-right: 1px dashed rgba(0, 0, 0, 0.4);
}



.footerLinks__contacts {
  width: 100%;
  height: 100%;
  max-height: 282px;
  padding: 60px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.footerLinks__logo {
  background: url("/landing/header/logo_black.svg") no-repeat;
  width: 180px;
  height: 24px;
}

.footerLinks__socialNetwork {
  width: 100%;
  max-width: 184px;
  display: flex;
  gap: 8px;
  //justify-content: space-between;
  align-items: center;
}

.facebook {
  background: url("/landing/footer/facebook.svg") center center no-repeat;
  height: 28px;
  width: 28px;
  background-size: cover;
}
.telegram {
  background: url("/landing/footer/telegram.svg") center center no-repeat;
  height: 28px;
  width: 28px;
  background-size: cover;
}
.vk {
  background: url("/landing/footer/vk.svg") center center no-repeat;
  height: 28px;
  width: 28px;
  background-size: cover;
}
.instagram {
  background: url("/landing/footer/instagram.svg") center center no-repeat;
  height: 28px;
  width: 28px;
  background-size: contain;
}

.megasredaLogo {
  background: url("/landing/footer/megasreda-logo.svg") center center no-repeat;
  height: 28px;
  width: 28px;
  background-size: contain;
}
.footerLinks__forWhom,
.footerLinks__company,
.footerLinks__aboutService {
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 8px;
  padding: 60px 20px 0 0px;
  //margin-top: 60px;
  //margin-bottom: 51px;
  //height: 120px;
}

//.footerLinks__aboutService {
//  padding: 50px 20px 0 0px;
//}

.footerLinks__point {
  padding-left: 20px;
}

.container_background_footerLinks__point {
  position: relative;
  right: 1px;
  border-left: 1px solid #282c38;
}

@media screen and (max-width: 1250px) {
  .footerLinks__el_void {
    display: none;
  }
}

@media screen and (min-width: 1250px) {
  .footerLinks__el_rightBorder{
    >:last-child{
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .footerLinks__el_rightBorder {
    border-right: none;
  }
}

@media screen and (max-width: 730px) {
  .footerLinks__elMob {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .footerLinks__el {
    border-right: 1px dashed rgba(0, 0, 0, 0.4);
    max-width: 100%;
  }
}

.footerInfo {
  padding: 15px 0;
  height: max-content;
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 540px) {
  .footerInfo {
    padding: 15px 0;
    align-items: center;
    flex-direction: column;
  }
}

.footerInfo__docs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
  align-items: flex-end;
}

.footerInfo__docsLink {
  padding: 0 10px;
}

@media screen and (max-width: 540px) {
  .footerInfo__docsLink{
    padding: 0;
  }
  .footerInfo__docs {
    margin-top: 15px;

    //flex-direction: row !important;
    //justify-content: center;
    flex-direction: column;
  }
}

.footerInfo__req{
  display: grid;
  grid-gap: 5px;
}

.footerInfo__paragraph {
  font-family: Golos, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.5;
  word-break: break-word;
}

.footerInfo__paragraph_link {
  text-decoration: underline;
}


.footerInfo__icon {
  height: 22px;
}


.footerLinks__emailPhone{
  display: flex;
  flex-direction: column;
  gap: 8px;
}