.title {
  font-family: Golos Text, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 110%;
  color: #ffffff;
  margin: 0;
}

.title_center {
  text-align: center;
}

@media screen and (max-width: 1200px) {

  .title {
    font-size: 32px;
  }
  .title_ambitiousCompanies_center {
    text-align: center;
  }
}

@media screen and (max-width: 540px) {

  .title {
    font-size: 24px;
  }
}

@import "_theme/title_theme_header";
@import "_color/title_color";
@import "_size/title_size_16";

.title_weight_normal {
  font-weight: normal;
  line-height: 120%;
}
