.exampleCustomerPage__image {
  position: absolute;
  background-image: url("/landing/to-customers/mainScreen_ru.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1180px;
  height: 900px;
  left: 0;
  top: 150px;
}

.exampleCustomerPage__image_en{
  background-image: url("/landing/to-customers/mainScreen_en.png");
}

.exampleCustomerPage__image_uz{
  background-image: url("/landing/to-customers/mainScreen_uz.png");
}
@media screen and (max-width: 540px) {
  .exampleCustomerPage__image {
    top: 100px;
  }
}
