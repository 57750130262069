.calendarModal {
  z-index: 8;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #00000061;
  display: none;
  justify-content: center;
  align-items: center;
}

.calendar_open {
  display: flex;
}
.calendar__title {
  font-family: Golos Text, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #282c38;
}

.calendar__paragraph {
  font-family: Golos Text, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #282c38;
}

.calendar__numOfTheMonthDayOfTheWeek {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: default;
}

.calendar__dateOfTheMonthDayOfTheWeek {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  &:hover {
    border-radius: 50%;
    background: #f1f4f4;
  }
}
.calendar__paragraphBorder {
  position: relative;
  border-radius: 50%;
  background: #3de297;
  z-index: 1;
  &:after {
    z-index: -1;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 30px;
    width: 15px;
  }
  &:hover {
    background: #3de297;
  }
}
.calendar__paragraphBorder_right {
  &:after {
    background: #3de29752;
    right: 0;
  }
}
.calendar__paragraphBorder_left {
  &:after {
    background: #3de29752;
    left: 0;
  }
}
.calendar__paragraph_gap {
  background: #3de29752;
}

.calendar {

  padding: 50px;

  background: #ffffff;
  border-radius: 10px;
  display: grid;
  grid-gap: 32px;
}

@media screen and (max-width: 600px) {
  .calendar {
    padding: 35px;
  }
}

.calendar__buttons {
  display: grid;
  grid-template-columns: 99px 99px;
  grid-gap: 8px;
}

.calendar__button {
  width: 99px;
  height: 36px;
}

.calendar__button_noActive {
  opacity: 0.5;
}
.calendar__button_white {
  background: white;
  color: #282c38;
  border: 1px solid #d7d8de;
  &:hover {
    background: white;
    border: 1px solid #737a92;
  }
}

.calendar__dates {
  //height: 100%;
  //max-height: 262px;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  //align-items: center;
  display: grid;
  grid-gap: 20px;
}
.calendar__dateOfTheMonth {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 40px;
}

@media screen and (max-width: 600px) {
  .calendar__dateOfTheMonth{
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}



.calendar__nextMonth {
  transform: rotate(-90deg);
}



.calendar__dateOfTheMonthDaysOfWeek {
  display: grid;
  grid-template-columns: repeat(7, 30px);
}

.calendarButton {
  @extend %paragraph;
  font-size: 12px;
  line-height: 140%;

  color: #282c38;

  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;

  grid-gap: 20px;
  padding: 4px 4px 4px 16px;

  position: static;
  //width: 170px;
  //height: 40px;

  cursor: pointer;

  border: 1px solid #d7d8de;
  box-sizing: border-box;
  border-radius: 10px;
  transition: border 0.5s;
  &:hover {
    transition: border 0.5s;
    border: 1px solid #737a92;
  }
}

@media screen and (max-width: 768px) {
  .calendarButton{
    width: 100%;
  }
}

.calendarButton__icon {
  width: 32px;
  height: 32px;

  background: #f1f4f4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    background: url("/icons/baseUI/calendar/calendar.svg") center center
      no-repeat;
    width: 20px;
    height: 20px;
  }
}

.inputCalendar{
  width: fit-content;
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 640px) {
  .inputCalendar_mobile_hidden{
    display: none;
  }
}
.inputCalendar__header{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  user-select: none;
}
.inputCalendar__arrow{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: 0.2s background-color;
  &:hover{
    background-color: #F1F4F4;
  }
  &:before{
    content: '';
    display: flex;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.inputCalendar__arrow_left:before{
  background-image: url("/icons/baseUI/arrow-icons/arrowDown.svg");
  transform: rotate(90deg);
}
.inputCalendar__arrow_right:before{
  background-image: url("/icons/baseUI/arrow-icons/arrowDown.svg");
  transform: rotate(-90deg);
}


.inputCalendar__twoArrow{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  width: auto;
  height: 30px;
  border-radius: 50%;
  transition: 0.2s background-color;
  &:hover{
    background-color: #F1F4F4;
  }
  &:before,&:after{
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.inputCalendar__twoArrow_left {
  &:before,&:after{
    background-image: url("/icons/baseUI/arrow-icons/arrowDown.svg");
    transform: rotate(90deg);
  }
}
.inputCalendar__twoArrow_right {
  &:before,&:after{
    background-image: url("/icons/baseUI/arrow-icons/arrowDown.svg");
    transform: rotate(-90deg);
  }
}

@media screen and (min-width: 640px) {
  .inputCalendar__arrow_hide_desktop{
    visibility: hidden;
  }
}
.inputCalendar__month{
  position: relative;
  cursor: pointer;
  @extend %paragraph;
  font-size: 14px;
  font-weight: 500;
  color: #282c38;

  display: flex;
  align-items: center;
  gap: 5px;
}
.inputCalendar__body{
  width: fit-content;
}
.inputCalendar__daysOfWeek{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 40px;
  width: 100%;
}
.inputCalendar__dayOfWeek{
  @extend %paragraph;
  font-size: 13px;
  font-weight: 600;
  color: #282c38;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputCalendar__days{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.inputCalendar__day{
  display: flex;
  justify-content: center;
}
.inputCalendar__dayValue{
  @extend %paragraph;
  font-size: 13px;
  font-weight: 400;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s background-color;
  color: #282c38;
  &:not(.inputCalendar__dayValue_disabled):not(.inputCalendar__dayValue_selected):hover{
    background-color: #F1F4F4;
  }
}
.inputCalendar__dayValue_disabled{
  color: #B3B7C7;
}
.inputCalendar__dayValue_current{
  color: #e13d3d;
}
.inputCalendar__dayValue_selected{
  background-color: #3DE297;
}


.inputCalendar__clearButton{
  height: 36px;
}