.qualities__whiteTriangle {
  top: 0;
  border: 40px solid transparent;
  border-left: 95vw solid #ffffff;
  border-top: 0;
  margin: 0;
  padding: 0;
  left: 0;
  align-self: flex-start;
}
