.example__safetyGlass {
  display: grid;
  grid-template-rows: 48px max-content;
  grid-gap: 48px;
}

@media screen and (max-width: 1200px) {
  .example__safetyGlass {
    grid-gap: 24px;
  }
}

@media screen and (max-width: 540px) {
  .example__safetyGlasses {
    grid-area: auto;
  }
}
