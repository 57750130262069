.header__fix {
  z-index: 5;
  position: fixed;

  background: white;
  // или
  //background: rgba(255,255,255,0.9);
  //backdrop-filter: blur(5 px );

  min-width: 100%;
  transition: 0.2s box-shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1200px) {
  .header__fix {
    padding: 0 40px;
  }
}
@media screen and (max-width: 540px) {
  .header__fix {
    padding: 0 20px;
  }
}
