.modalWindow {

}

.modalWindow__background{

  position: fixed;
  z-index: 10;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: 0.5s;

  padding: 20px;

  overflow-y: scroll;
  visibility: hidden;

  display: grid;
  justify-items: center;
  align-items: center;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}


.modalWindow__background_show{
  background: #00000091;
  visibility: visible;
}

@media screen and (max-width: 540px) {
  .modalWindow__background_show{
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 540px) {
  .modalWindow__background{
    padding: 20px 20px 60px 20px;
  }
}

@media screen and (max-width: 540px) {
  .modalWindow__background {
    padding: 20px;

    &::-webkit-scrollbar {
      width: 0;
    }

    scrollbar-width: none;
  }
}

@media screen and (max-width: 480px) {
  .modalWindow__background {
    padding: 5px;
  }
}



.modalWindow__content {

  position: relative;
  background: transparent;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s;
  visibility: hidden;
  top: 20px;
  opacity: 0;

  margin: auto;

}

.modalWindow__content_onboarding{
  width: 100%;
  max-width: 750px;
}

.modalWindow__content_over_hidden {
  overflow: hidden;
}

.modalWindow__content_auth{
  width: 100%;
  height: 100%;
  max-height: 700px;
  max-width: 1000px;
}

.modalWindow__close {
  cursor: pointer;
  position: absolute;
  background: url("/landing/modalAuth/closeModal.svg") center center no-repeat;
  height: 24px;
  width: 24px;
  background-size: contain;
  top: 15px;
  right: 20px;
  z-index: 10;
}
