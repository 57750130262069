.example__bigTitle {
  font-size: 90px;
}

@media screen and (max-width: 1200px) {
  .example__bigTitle {
    font-size: 80px;
  }
}

@media screen and (max-width: 540px) {
  .example__bigTitle {
    font-size: 32px;
  }
}
