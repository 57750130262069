.appealForm__phone {
  height: 100%;
  width: 475px;
  background-image: url("/landing/main/phone_2.svg") ;
  z-index: 0;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1200px) {
  .appealForm__phone {
    height: 397px;
    width: 475px;
    background-size: cover;
    z-index: 1;
  }
}

@media screen and (max-width: 640px) {
  .appealForm__phone {
    height: 207px;
    width: 280px;
    background-position: center top;
    background-size: cover;
    z-index: 1;
  }
}
