.examples {
  height: auto;
  width: 100%;

  display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-gap: 100px;
  justify-items: center;
  padding: 40px 20px 0px;

  grid-template-columns: 1fr;

    @media screen and (max-width: 800px) {
        grid-gap: 40px;
    }

  &:before {
    position: absolute;
    content: "";
    margin: auto;
    top: 0px;
    //bottom: 400px;
    left: 0px;
    background: url("/landing/to-customers/vector_gray.svg") center center
      no-repeat;
    width: 3494.39px;
    height: 2725.25px;
    background-size: contain;
    opacity: 0.2;
    transform: rotate(45deg);
  }
}

@import "__bigTitle/examples__bigTitle";
@import "__title/examples__title";
@import "__content/examples__content";

@import "__iceberg/example__iceberg";
@import "__text/example__text";
@import "__textAtTop/example__textAtTop";
@import "__textParagraph/example__textParagraph";
@import "__paragraphTop/example__paragraphTop";
@import "__safetyGlasses/example__safetyGlasses";
@import "__safetyGlass/example__safetyGlass";
@import "__safetyGlassImg/example__safetyGlassImg";
@import "__safetyParagraph/example__safetyParagraph";
@import "__grid/example__grid";
