.exampleCustomerPage__folder {
  position: absolute;
  background-image: url("/landing/to-customers/folder.png");
  background-position: center center ;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80%;
  max-width: 730px;
  height: 548px;
  left: -54px;
}

.exampleCustomerPage__folder_en{
  background-image: url("/landing/to-customers/folder_en.png");
}

.exampleCustomerPage__folder_void {
  position: static;
  background: transparent;
  width: 100%;
  min-width: 540px;
  max-width: 830px;
  height: 548px;
}

@media screen and (max-width: 900px) {
  .exampleCustomerPage__folder_void {
    display: none;
  }
  .exampleCustomerPage__folder {
    position: relative;
    height: 240px;
    width: 100%;
    left: unset;
  }
}
