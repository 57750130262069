.button {
  @extend %paragraph;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: none;
  border-radius: 50px;
  background: #ffffff;
  width: max-content;
  position: relative;
  font-weight: 400;
  appearance: none;

  transition: .5s;

  padding: 0 15px;
}

.button_black{
  @extend %paragraph;
  color: white;
  background-color: #282c38;

  &:not(:disabled):hover {
    background-color: #4c4f5a;
    color: white;
  }
}

.button_medium{
  height: 38px;
  font-size: 13px;
  padding: 0 15px;
}
.button_large{
  height: 45px;
  font-size: 13px;
  padding: 0 30px;
}

.button_big{
  height: 50px;
  font-size: 13px;
  padding: 0 30px;
}


.button_red{
  background:#F7E8E6 ;
  color: #c8483a;
  box-shadow: 0 0 2px 0 #F7E8E6;

  &:not(:disabled):hover {
    background-color: #f3c3bd;
    color: #c8483a;
    box-shadow: 0 0 2px 0 #c8483a;
  }
}

.button_orange{
  background:#FFE6D8 ;
  color: #ff9c28;
  box-shadow: 0 0 2px 0 #FFE6D8;

  &:not(:disabled):hover {
    background-color: #f8ccb3;
    color: #fd9419;
    box-shadow: 0 0 2px 0 #ff9c28;
  }
}
.button_green{
  background:#E6F7EC ;
  color: #30d52c;
  box-shadow: 0 0 2px 0 #30d52c;
  &:not(:disabled):hover {
    background-color: #b4f6cc;
    color: #25d521;
    box-shadow: 0 0 2px 0 #25d521;
  }
}

.button_mediumWide{
  height: 38px;
  font-size: 13px;
  padding: 0 25px;
}

.button_largeWide{
  flex-shrink: 0;
  height: 45px;
  font-size: 13px;
  padding: 0 30px;
}

.button__text-hidden{
  visibility: hidden;
}

.button__loading-icon{
  position: absolute;
  width: 25px;
  height: 25px;
}

.button:disabled{
  opacity: 0.7;
  cursor: default;
}

.button_maxWidth {
  width: 100%;
}

//.button_medium{
//  padding: 5px 30px;
//}

.button_padding_landing {
  padding: 16px 32px;
}

.button_padding_account {
  padding: 8px 16px;
}

.button__circle {
  background: #282c38;
  border-radius: 40px;
  min-height: 48px;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button__arrow {
  background: url("/landing/header/arrow__login.svg") center center no-repeat;
  height: 18px;
  width: 18px;
}


@import "_theme/button_theme_banner";
@media screen and (max-width: 540px) {
  .button_theme_registration {
    width: 100%;
    max-width: 100%;
  }
}

.button__greyButton {
  background: #dde3e3;
  color: #282c38;
}

.button__blackButton, %button__blackButton {
  @extend %paragraph;
  color: white;
  background: #282c38;

  &:not(:disabled):hover {
    background: #4c4f5a;
    color: white;
  }
}

%button__blackButton{
  padding: 8px 24px;
}

@media screen and (max-width: 1100px) {
  .button__blackButton {
    padding: 6px 16px;
  }
}

.button__whiteButton {
  @extend %paragraph;
  color: #282c38;

  border: 1px solid #d7dbeb;
  box-sizing: border-box;

  &:hover {
    background: white;
    border: 1px solid #737a92;
  }
}

.button__p_16{
  padding: 0 16px;
}

.button__greenButton {
  background: #3de297;
  color: #282c38;
}

.button__whiteGreenButton {
  &:hover {
    background: #edfff7;
    transition: 0.5s;
    color: #3de297;
    .button__circle {
      transition: 0.5s;
      background: #3de297;
    }
    .paragraph {
      transition: 0.5s;
      color: #3de297;
    }
  }
}

.button__transparent {
  border: 1px solid #282c38;
  background: transparent;

  font-size: 16px;
  color: #282c38;
}
.button_disabled {
  background: #d7d8de;
  cursor: default;
  &:hover {
    background: #d7d8de;
  }
}

.button_maxHeight{
  height: 100%;
}

