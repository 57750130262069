.FAQ__answerText {
  width: 100%;
  max-width: 600px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #737a92;
  padding-right: 40px;
}
