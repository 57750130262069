.optimization__description {
  width: 480px;
  height: 508px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .optimization__description {
    width: 360px;
    height: auto;
  }
}

@media screen and (max-width: 540px) {
  .optimization__description {
    width: 100%;
    max-width: 480px;
  }
}
