.container_background_advantages {
  min-height: 600px;
  position: relative;
  background: linear-gradient(354.5deg, #ffffff -25.54%, #eaf6f9 114.37%);
  align-items: end;
}

@media screen and (max-width: 710px) {
  .container_background_advantages {
    height: 800px;
  }
}

@media screen and (max-width: 580px) {
  .container_background_advantages {
    height: 1200px;
  }
}

@media screen and (max-width: 540px) {
  .container_background_advantages {
    height: 1000px;
  }
}
