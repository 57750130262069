.team {
  //height: 780px;
  height: max-content;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
}

.team__content {
  height: 618px;
  max-width: 1229px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team__image {
  background-image: url("/landing/to-customers/team.png") ;
  background-position: center center ;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 600px;
}

.team__image_en{
  background-image: url("/landing/to-customers/team_en.png") ;
}

.team__text {
  max-width: 480px;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 33px;
}

@media screen and (max-width: 1200px) {
  .team__content {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-gap: 50px;
    justify-content: center;
  }
  .team__image {
    width: 100%;
    height: 300px;
  }
}
