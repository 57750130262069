.coupleOfBlocks__our {
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  box-shadow: 0px 55px 104px -20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;

  padding: 50px 60px;

  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;

  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 158px;
}
@media screen and (max-width: 1200px) {
  .coupleOfBlocks__our {
    padding: 40px;
  }
}

@media screen and (max-width: 600px) {
  .coupleOfBlocks__our {
    //grid-gap: 43px;
    padding: 20px;
    grid-gap: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.coupleOfBlocks__us_target {
  background-image: url("/landing/about/usTarget.png");
}

.coupleOfBlocks__us_mission {
  background-image: url("/landing/about/usMission.png");
}

.coupleOfBlocks__ourParagraph {
  font-size: 20px;
}

.coupleOfBlocks__ourParagraph_small {
  max-width: 361px;
}

@media screen and (max-width: 600px) {
  .coupleOfBlocks__ourParagraph {
    font-size: 14px;
  }
}

.coupleOfBlocks__usTargetParagraph,
.coupleOfBlocks__usMissionParagraph {
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .coupleOfBlocks__usTargetParagraph,
  .coupleOfBlocks__usMissionParagraph {
    font-size: 14px;
  }
}

.coupleOfBlocks__usMissionParagraph {
  max-width: 361px;
}
