.paragraph_theme_link {
  cursor: pointer;
  //font-size: 12px;
  color: #3d6be2;
  text-decoration: underline #3d6be2;
  padding: 0;
  margin: 0;
  word-break: break-word;
}

.paragraph_theme_underline {
  border-bottom: 1px solid #ffffff;
}
