.authorization {
  z-index: 10;

  display: flex;
  position: fixed;

  justify-content: center;
  align-items: center;

  margin: auto;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  height: 100%;
  width: 100%;

  background: #00000091;

  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  padding-top: 20px;
}

@import "__field/authorization__field";
@import "__errorField/authorization__errorField";
@import "__warningIcon/authorization__warningIcon";
