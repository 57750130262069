.header__logo {
  background: url("/landing/header/logo_white.svg") no-repeat;
}

.header__logo_black {
  background: url("/landing/header/logo_black.svg") no-repeat;
}

@media screen and (min-width: 1200px) {
  .header__logo,
  .header__logo_black {
    width: 180px;
    height: 24px;
  }
  .header__logoPhone {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .header__desktop {
    background: url("/landing/header/logo_black.svg") no-repeat;
    width: 130px;
    height: 18px;
  }
  .header__logoPhone,
  .header__logo_black {
    width: 140px;
    height: 18px;
  }
}

@media screen and (max-width: 540px) {
  .header__logoPhone,
  .header__logo_black {
    width: 112px;
    height: 14px;
  }
}
