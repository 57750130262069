.blog__news {
  display: grid;
  grid-template-rows: max-content 300px;
  grid-gap: 32px;
  transition: 0.5s;
}

@media screen and (max-width: 540px) {
  .blog__news {
    display: grid;
    grid-template-rows: max-content 300px;
  }
}

@media screen and (min-width: 700px) {
  .blog__news_first {
    grid-area: 1/1/2/-1;

    grid-template-rows: 400px;
    grid-template-columns: minmax(200px, 680px) minmax(300px, 1fr);
    grid-gap: 64px;
  }
}
