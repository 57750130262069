.example__safetyGlassImg {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-left: 20px;
}

.example__safetyGlassImg_shield {
  background-image: url("/landing/to-customers/securityIcons/shild.svg");
}
.example__safetyGlassImg_api {
  background-image: url("/landing/to-customers/securityIcons/safe.svg");
}
.example__safetyGlassImg_castle {
  background-image: url("/landing/to-customers/securityIcons/check.svg");
}
.example__safetyGlassImg_key {
  background-image: url("/landing/to-customers/securityIcons/key.svg");
}
.example__safetyGlassImg_user {
  background-image: url("/landing/to-customers/securityIcons/check.svg");
}

.example__safetyGlassImg_currency {
  background-image: url("/landing/to-freelancers/icons/world.svg");
}
.example__safetyGlassImg_message {
  background-image: url("/landing/to-freelancers/icons/safe.svg");
}
.example__safetyGlassImg_time {
  background-image: url("/landing/to-freelancers/icons/blank.svg");
}
.example__safetyGlassImg_shieldFree {
  background-image: url("/landing/to-freelancers/icons/cash.svg");
}
