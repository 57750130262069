//.input, %input {
//  padding: 16px 40px 16px 24px;
//  //height: 54px;
//  height: 100%;
//  width: 100%;
//  border: 1px solid #d7d8de;
//  box-sizing: border-box;
//  border-radius: 10px;
//  text-overflow: ellipsis;
//
//  &::-webkit-outer-spin-button,
//  &::-webkit-inner-spin-button {
//    -webkit-appearance: none;
//    margin: 0;
//  }
//
//}


.input, .textarea, %input
{
  @extend %paragraph;
  color: #282C38;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid #d7d8de;
  width: 100%;
  transition: 0.2s border;
  font-size: 14px;

  &::placeholder{
    color: #737A92;
    font-size: 13px;
  }
  &::-webkit-input-placeholder {
    color: #737a92;
    font-size: 13px;
  }
  &:hover{
    border: 1px solid #737A92;
  }
  &:focus {
    outline: none;
    border: 1px solid #737A92;
  }
}



.input, %input
{
  height: 48px;
  padding-left: 15px;
}

%input_40 {
  height: 40px;
}

.textarea{
  height: 120px;
  padding: 15px;
  resize: none;

}
.textarea_small{
  height: 56px;
  font-size: 8px;
  &::-webkit-input-placeholder {
    color: #737a92;
    font-size: 8px;
  }
}

.input_margin-bottom{
  margin-bottom: 7px;
}

.input_phone {
  padding-left: 100px;
}

.input__codeList {
  background: url("/icons/baseUI/arrow-icons/arrowDown.svg") no-repeat center;
  height: 12px;
  width: 12px;
  background-size: contain;
}
.phone-code{
  font-size: 14px;
}



.input__phoneCode {
  cursor: pointer;
  position: absolute;
  height: 35px;
  width: 90px;
  top: 7px;
  bottom: 0;
  left: 6px;
  border: none;
  background: #e4eaea;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  //opacity: 0.7;

}

%element-loading{
  animation-duration: 0.7s;
  animation-name: highlights;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes highlights {
  from {
    opacity: 1;
    //background-position: 0% 0%;
  }

  to {
    opacity: 0.5;
    //background-position: 100% 0%;
  }
}
.input__phoneCodeFlag
{
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.inputHolder{
  position: relative;
}
.input_error {
  border: 1px solid #df2323 !important;
  background: rgba(255, 255, 255, 0.1);
}

.input__phoneList {
  display: block;
  position: absolute;
  top: calc(100%);
  width: 100%;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s, visibility .5s, top .5s;

  padding: 5px;
  pointer-events: none;
}


.input__phoneCode_show {
  top: calc(100% + 8px);
  opacity: 1;
  visibility: visible;
  pointer-events: unset;
}

.input__phoneList__content {
  width: 100%;
  height: 100%;
  max-height: 226px;
  overflow-y: scroll;
}
.input__phoneList__content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #D7DBEB;
  background-position: center;
  background-repeat: no-repeat;
  height: 5px;
}
.input__phoneList__content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.input__phoneCode_loading{
  @extend %element-loading;
}



.authorization__phoneField_loading{
  background-color: #eeeeee;
  @extend %element-loading;
  border-radius: 10px;
  & * {
    display: none;
  }
}





.input__phoneCodeCountry {
  cursor: pointer;
  width: 100%;
  display: flex;
  height: max-content;
  align-items: center;
  border-radius: 6px;
  padding: 10px 10px;
  &:hover {
    background: #f1f4f4;
  }
}

.input__phoneInList {
  margin-left: 8px;
}

.input__phoneListSearch {
  position: relative;
  display: flex;
  width: 100%;
}
.input__phoneListSearchImage {
  position: absolute;
  top: 10px;
  left: 16px;
  background: url("/icons/baseUI/search/search.svg");
  background-size: contain;
  width: 16px;
  height: 16px;
}

.input__phoneListInput {
  border: none;
  padding: 8px 0px 8px 50px;
  height: 36px;
}
