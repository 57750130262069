.formError{
  display: flex;
  width: 100%;
  align-items: center;
}
.formError__icon{
  background: url("/landing/modalAuth/alert.svg") bottom no-repeat;
  background-size: contain;
  height: 16px;
  min-width: 16px;
}
.formError__text{
  color: #df2323;
  font-size: 12px;
  line-height: 1;
  padding-left: 5px;
  &::first-letter {
    text-transform: uppercase;
  }
}
